import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
});

export const firestore = firebase.firestore()
const authContext = createContext();
export const storage = firebase.storage()

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
  }
  
  export const useAuth = () => {
    return useContext(authContext);
  }

  export const createUserProfileDoc =  async (user, additionalData) => {
    if (!user) return
    const userRef = firestore.doc(`users/${user.uid}`)
    const snapshot = await userRef.get()
    
    if (!snapshot.exists) {
      const { displayName, email } = user
      const createdAt = new Date()
      try {
        await userRef.set({
          displayName,
          email,
          createdAt,
          ...additionalData
        })
      } catch (error) {
        console.error(error)
      }
    }
    return getUserDocument(user.uid)
  }

  export const getUserDocument = async (uid) => {
    if (!uid) return null
    
    try {
      const userDocument = await firestore.collection('users').doc(uid).get()
  
      return { uid, ...userDocument.data() }
    } catch (error) {
      console.error(error)
    }
  }

  function useProvideAuth() {
    const [user, setUser] = useState(null);
  
    const signin = (email, password) => {
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(response => {
          setUser(response.user);
          return response.user;
        });
    };
  
    const signout = () => {
      return firebase
        .auth()
        .signOut()
        .then(() => {
          setUser(false);
        });
    };
  
    useEffect(() => {
      const unsubscribe = firebase.auth().onAuthStateChanged(async userAuth => {
        const user = await createUserProfileDoc(userAuth)
        if (user) {
          setUser(user);
        } else {
          setUser(false);
        }
      });
  
      // Cleanup subscription on unmount
      return () => unsubscribe();
    }, []);
    
    // Return the user object and auth methods
    return {
      user,
      signin,
      signout,
    };
  }