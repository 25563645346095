import requestServiceImage1 from '../../assets/images/request-a-service/requestservice1.png'
import RequestServiceBubbles from '../BackgroundBubbles/RequestAServiceBubbles/RequestAServiceBubbles'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import RequestForm from './RequestForm'
import map from '../../assets/images/request-a-service/map.png'
import referralForm from '../../assets/Documents/portals/patient/_referral-form.pdf'
import bottomImage from '../../assets/images/request-a-service/thank-you.png'
const RequestAService: React.FC = () => {
    const [ isOpen, setIsOpen ] = useState(false)

    return (
        <div className="request-a-service">
            <RequestServiceBubbles />
            <div className="request-a-service__top">
                <h1>Let's get started together!</h1>
                <div className="image">
                    <img src={requestServiceImage1} alt="Spark Home Health | Request a Service | Denver, CO" />
                </div>
            </div>
            <div className="request-a-service__message-1">
                <p>
                    We are dedicated to making sure each patient is partnered with a highly qualified Spark
                    Home Health clinician. Our personalized referral process ensures each patient and family
                    feels well taken care of. We pride ourselves on being a different kind of home health
                    agency; in the rare instance our Spark Home Health team cannot provide care, our
                    referral specialists will reach out to our extended local provider network so that every child
                    easily receives the high quality care they deserve.
                </p>
            </div>
            <div className="request-a-service__btns">
                <button onClick={() => setIsOpen(true)} className="extend">I'm a Parent/Caregiver</button>
                <button><a href={referralForm} target="_blank">I'm a Provider</a></button>
            </div>
            <div className="request-a-service__message-2">
                <p>
                    We are proud to serve families and patients in the the
                    following locations near you. Longmont, Lafayette,
                    Boulder, Brighton, Erie, Broomfield, Northglenn,
                    Thornton, Westminster, Arvada, Golden, Wheat Ridge,
                    Denver (including Green Valley Ranch), Commerce City,
                    Lakewood, Littleton, Highlands Ranch, Centennial,
                    Lone Tree, Aurora and Parker
                </p>
            </div>
            <div className="request-a-service__form">
                <RequestForm isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
            <div className="request-a-service__map">
                <img src={map} alt="Spark Home Health | Location Map | Denver CO" />
            </div>
            <div className="bottom-image">
                <div className="back-bub-1" />
                <div className="back-bub-2" />
                <div className="back-bub-3" />
                <img src={bottomImage} alt="Spark Home Health | Request a Service | Denver, CO" />
            </div>
            <div className="request-service-message">
                <p>contact us to see a provider near you</p>
            </div>
        </div>
    )
}

export default RequestAService