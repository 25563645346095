const ResourceBookBubbles: React.FC = () => {
    return (
        <div>
            <div className="resource-book-bubble1" />
            <div className="resource-book-bubble2" />
            <div className="resource-book-bubble3" />
            <div className="resource-book-bubble4" />
            <div className="resource-book-bubble5" />
            <div className="resource-book-bubble6" />
            <div className="resource-book-bubble7" />
            <div className="resource-book-bubble8" />
            <div className="resource-book-bubble9" />
            <div className="resource-book-bubble10" />
            <div className="resource-book-bubble11" />
            <div className="resource-book-bubble12" />
            <div className="resource-book-bubble13" />
            <div className="resource-book-bubble14" />
            <div className="resource-book-bubble15" />
            <div className="resource-book-bubble16" />
        </div>
    )
}

export default ResourceBookBubbles