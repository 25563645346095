const PageNotFound: React.FC = () => {
    return (
        <div className="page-not-found">
            <div className="page-not-found__message">
                <h1>404</h1>
                <p>Page Not Found.</p>
            </div>
        </div>
    )
}

export default PageNotFound