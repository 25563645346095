import CovidResponseBubbles from '../BackgroundBubbles/CovidResponseBubbles'
import covid1 from '../../assets/images/covid-response/covid1.png'
import covid2 from '../../assets/images/covid-response/covid2.png'
import covid3 from '../../assets/images/covid-response/covid3.png'
import { Link } from 'react-router-dom'

const CovidResponse: React.FC = () => {
    return (
        <div className="covid-response">
            <CovidResponseBubbles />
            <div className="covid-response__statement">
                <p className="text">Spark Home Health was the first home health agency to utilize telemedicine services as a solution to combat the spread of the COVID-19 virus. We worked quickly to ensure the children and families we serve continued to receive exemplary services while reducing the contact we had with members of the community. The health and safety of our patients, team members, and the greater community is our top priority. As an agency, we will continue to lead by example and use all local and federal resources available to guide our decisions.</p>
                <div className="circle">
                    <div className="back-bub-3" />
                    <div className="back-bub-4" />
                    <p>Centers for Disease Control and Prevention website <a href="https://www.cdc.gov/" target="_blank">(https://www.cdc.gov/)</a></p>
                </div>
            </div>
            <div className="covid-response__measures">
                <div className="box">
                    <div className="bubble-1">
                        <div className="back-bub-1" />
                        <div className="back-bub-2" />
                        <img src={covid1} alt="Spark Home Health | Boy with mask on" />
                    </div>
                    <div className="bubble-2">
                        <div className="back-bub-5" />
                        <div className="back-bub-6" />
                        <img src={covid2} alt="Spark Home Health | Girl with mask on" />
                    </div>
                    <div className="text-circle">
                        <div className="back-bub-mob-1" />
                        <div className="back-bub-mob-2" />
                        <p>For additional information regarding the COVID-19 pandemic, please refer to the State of Colorado’s COVID-19 Resource Page</p>
                        <a href="https://covid19.colorado.gov/" target="_blank">(https://covid19.colorado.gov/)</a>
                    </div>
                    <h1>Measures We Are Taking to Keep You Safe</h1>
                    <p>Regardless of whether or not a staff member has received the COVID-19 vaccination our patient families can expect the following during an in-person visit at this time:</p>
                    <ul>
                        <li><strong>Daily Screenings and Temperature Checks:</strong> Before making an in-person visit anyone participating in the visit, including the therapist, will answer COVID-19 screening questions and take and record temperatures.</li>
                        <li><strong>Personal Protective Equipment:</strong> To protect all members of our extended Spark Home Health family, clinicians will be wearing masks to all in-home appointments at this time. Gloves, goggles, face shields and gowns may be utilized in addition to hand washing.</li>
                        <li><strong>No Toy Bags:</strong> During the global pandemic our team will not bring therapy materials into the home unless those materials will stay with you (such as printed handouts). Each therapist will work with each family to identify items in the home that can be used to enhance therapy sessions.</li>
                        <li><strong>Risk and Contact Tracing Precautions:</strong> Our Infection Control Officer and Nurse Practitioner works to clear patients for in-person services on a case by case basis. We continue to track all COVID-19 diagnosis, exposures, and risks (including travel) for our patients and clinicians and use CDC guidance to take the necessary precautions, such as providing Telemedicine Services, whenever needed.</li>
                    </ul>
                </div>
            </div>
            <div className="covid-response__learn-more">
                <Link to="/telemedicine">
                    <div className="image">
                        <img src={covid3} alt="Spark Home Health | Telemedicine Button" />
                    </div>
                    <div className="content">
                        <h1>Learn More About Telemedicine Services</h1>
                        <p>In addition to in-person visits, our organization also utilizes telemedicine  to empower children and caregivers so they may meet and exceed individual goals.  Find out what to expect from telemedicine and how to get the most from virtual visits with your Spark therapist.</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default CovidResponse