const EmployeeLoginBubbles: React.FC = () => {
    return (
        <div>
            <div className="employee-login-bubble1" />
            <div className="employee-login-bubble2" />
            <div className="employee-login-bubble3" />
            <div className="employee-login-bubble4" />
            <div className="employee-login-bubble5" />
            <div className="employee-login-bubble6" />
            <div className="employee-login-bubble7" />
            <div className="employee-login-bubble8" />
            <div className="employee-login-bubble9" />
            <div className="employee-login-bubble10" />
            <div className="employee-login-bubble11" />
            <div className="employee-login-bubble12" />
            <div className="employee-login-bubble13" />
            <div className="employee-login-bubble14" />
            <div className="employee-login-bubble15" />
            <div className="employee-login-bubble16" />
        </div>
    )
}

export default EmployeeLoginBubbles