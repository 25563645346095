import ACHC from '../../assets/images/footer/achc.png'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="footer__logo">
                <a href="https://www.achc.org/" target="_blank"><img src={ACHC} alt="Spark Home Health | ACHC Accredited" /></a>
            </div>
            <div className="footer__contact">
                <p className="footer-title">Spark Home Health</p>
                <a href="https://goo.gl/maps/EhCmL5CMCHbQiaap7" target="_blank">
                    1325 South Colorado Boulevard,<br />
                    Suite #B 312, Denver, CO 80222<br />
                </a>
                <p><a href="tel:720-479-8952">Phone: 720-479-8952</a></p>
                <p><a href="tel:888-981-8064">Fax: 888-981-8064</a></p>
            </div>
            <div className="footer__logins">
                <button><Link to="/patient-login">Patient Login</Link></button>
                <button><Link to="/employee-login">Employee Login</Link></button>
            </div>
        </div>
    )
}

export default Footer