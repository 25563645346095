const JoinOurTeamBubbles: React.FC = () => {
    return (
        <div>
            <div className="join-team-bubble1" />
            <div className="join-team-bubble2" />
            <div className="join-team-bubble3" />
            <div className="join-team-bubble4" />
            <div className="join-team-bubble5" />
            <div className="join-team-bubble6" />
            <div className="join-team-bubble7" />
            <div className="join-team-bubble8" />
            <div className="join-team-bubble9" />
            <div className="join-team-bubble10" />
            <div className="join-team-bubble11" />
            <div className="join-team-bubble12" />
            <div className="join-team-bubble13" />
            <div className="join-team-bubble14" />
            <div className="join-team-bubble15" />
            <div className="join-team-bubble16" />
        </div>
    )
}

export default JoinOurTeamBubbles