import thankYou from '../../assets/images/request-a-service/thank-you.png'
import RequestConfirmationBubbles from '../BackgroundBubbles/RequestAServiceBubbles/RequestConfirmationBubbles'

const RequestConfirmation: React.FC = () => {
    return (
        <div className="request-confirmation">
            <RequestConfirmationBubbles />
            <div className="request-confirmation__message">
                <div className="inner">
                    <div className="back-bub-1" />
                    <div className="back-bub-2" />
                    <h1>Thank you for contacting us</h1>
                    <p>
                        Our Team will contact you within one business day to obtain additional information regarding the services you are requesting.
                    </p>
                </div>
                <div className="image">
                    <div className="back-bub-3" />
                    <div className="back-bub-4" />
                    <img src={thankYou} alt="Spark Home Health | Thank You | Denver, CO" />
                </div>
            </div>
        </div>
    )
}

export default RequestConfirmation