import { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface openProps {
    isOpen: boolean,
    setIsOpen(arg: boolean): void
}

const RequestForm: React.FC<openProps> = (props) => {
    const [ formData, setFormData ] = useState<any>({})
    const [ selectedOption, setSelectedOption ] = useState('')
    const history = useHistory()

    const clearInputs = () => {
        setFormData({})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        fetch('https://us-central1-spark-home-health-a51c9.cloudfunctions.net/app/api/caregiver-form', {
            method: 'POST',
            body: JSON.stringify({ ...formData, selectedOption })
        })
        history.push('/thank-you')
        clearInputs()
    }

    const handleChange = (e) => {
        setFormData(prevFormData => ({
            ...prevFormData, 
            [e.target.name]: e.target.value
        }))
    }

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value)
    }

    return (
        <div className={`request-form ${props.isOpen ? "open" : ""}`}>
            <div className="request-form__inner">
                <form>
                    <h1>Parent & Caregiver Form</h1>
                    <div className="request-form__section">
                        <label htmlFor="name">Parent or Caregiver Name</label>
                        <input id="name" name="name" type="text" value={formData.name || ''} onChange={handleChange} />
                    </div>
                    <div className="request-form__section">
                        <label htmlFor="language">Preferred Language</label>
                        <input id="language" name="language" type="text" value={formData.language || ''} onChange={handleChange} />
                    </div>
                    <div className="request-form__section">
                        <label htmlFor="phone number">Phone Number</label>
                        <input id="phone number" name="phone" type="phone" value={formData.phone || ''} onChange={handleChange} />
                    </div>
                    <div className="request-form__section">
                        <label htmlFor="communication" className="select-label">Do you prefer to receive communication via text?</label>
                        <div className="radios">
                            <label className="container" htmlFor="yes">Yes
                                <input 
                                    id="yes"
                                    type="radio" 
                                    name="question-one" 
                                    value="Yes"
                                    checked={selectedOption === 'Yes'}
                                    onChange={handleOptionChange} 
                                />
                                <span className="checkmark"></span>
                            </label>
                            <label className="container" htmlFor="no">No
                                <input 
                                    id="no"
                                    type="radio" 
                                    name="question-one" 
                                    value="No"
                                    checked={selectedOption === 'No'}
                                    onChange={handleOptionChange} 
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div className="request-form__section">
                        <label htmlFor="service">Type of service requested</label>
                        <select id="service" name="service" onChange={handleChange}>
                            <option>- Choose a Service -</option>
                            <option value="nursing">Acute Nursing</option>
                            <option value="occupational-therapy">Occupational Therapy</option>
                            <option value="physical-therapy">Physical Therapy</option>
                            <option value="speech-language">Speech-Language Pathology</option>
                            <option value="feeding">Feeding Therapy</option>
                            <option value="feeding">NICU Transition Program</option>
                        </select>
                    </div>
                    <div className="request-form__section">
                        <label htmlFor="insurance">Patient Insurance Type</label>
                        <input id="insurance" name="insurance" type="text" value={formData.insurance || ''} onChange={handleChange} />
                    </div>
                    <div className="request-form__section address-section">
                        <label htmlFor="address">Address</label>
                        <div className="address-boxes">
                            <input id="address" name="address1" type="text" value={formData.address1 || ''} onChange={handleChange} />
                            <input id="address" name="address2" type="text" value={formData.address2 || ''} onChange={handleChange} />
                        </div>
                    </div>
                    <button type="submit" 
                        className={`${
                            !formData.name || 
                            !formData.language ||
                            !formData.address1 ||
                            !formData.insurance ||
                            !formData.phone ||
                            !selectedOption ?
                            "disabled" : ""
                        }`}
                        disabled={
                            !formData.name || 
                            !formData.language ||
                            !formData.address1 ||
                            !formData.insurance ||
                            !formData.phone ||
                            !selectedOption
                        }
                        onClick={handleSubmit}>Submit
                    </button>
                    <div className="close" onClick={() => props.setIsOpen(false)}>
                        <div className="close__bar" />
                        <div className="close__bar" />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RequestForm