import JoinOurTeamBubbles from '../BackgroundBubbles/JoinOurTeamBubbles'
import { Link } from 'react-router-dom'
import join1 from '../../assets/images/JoinOurTeam/join1.png'
import join2 from '../../assets/images/JoinOurTeam/join2.png'
import join3 from '../../assets/images/JoinOurTeam/join3.png'
import join4 from '../../assets/images/JoinOurTeam/join4.png'

const JoinOurTeam: React.FC = () => {
    return (
        <div className="join-our-team">
            <JoinOurTeamBubbles />
            <div className="join-our-team__statement">
                <p>Thank you for your interest in joining team Spark! Ours is a close-knit team dedicated to creating opportunities for ongoing education, care coordination and patient support. For us, organizational growth is an intentional process. We don’t hire people en masse as we value quality over quantity. On average our clinicians have 10+ years of pediatric experience; those new to pediatric home health are supported through an intensive mentorship program. We hope you will consider exploring employment opportunities with Spark Home Health as we continue to ensure our collective journey makes a meaningful difference in the lives of our patients, their families and our employees.</p>
                <button><a href="https://na1.empforce.com/Recruiting/Applicant/JobListings.aspx?Cd=H1n3nDEKgKM%3d" target="_blank">Join Our Team</a></button>
                <div className="top-left-image">
                    <div className="back-bub-1" />
                    <div className="back-bub-2" />
                    <img src={join1} alt="Spark Home Health | Kids holding hands in a circle" />
                </div>
                <div className="quote-bubble">
                    <div className="back-bub-3" />
                    <div className="back-bub-4" />
                    <p>"I love being a member of the team and feel extremely appreciated and supported as a provider!"<br /> - Spark Team Member</p>
                    <div className="arrow" />
                </div>
                <div className="bottom-right-image">
                    <div className="back-bub-5" />
                    <div className="back-bub-6" />
                    <img src={join2} alt="Spark Home Health | lady and girl smile" />
                </div>
            </div>
            <div className="join-our-team__benifits">
                <div className="benifits-box">
                    <h1>Team Member Benefits</h1>
                    <ul>
                        <li>Health, Vision, and Dental Insurance</li>
                        <li>Retirement Match</li>
                        <li>Paid Time Off</li>
                        <li>Competitive Compensation</li>
                        <li>Guaranteed Life Insurance</li>
                        <li>Flexible Schedule</li>
                        <li>Supportive Clinical and Administrative Team</li>
                        <li>Opportunities for Care Coordination and Teaming</li>
                        <li>Continuing Education Opportunities</li>
                    </ul>
                </div>
                <div className="image-and-quote">
                    <div className="quote-bubble-2">
                        <p>"The fact we are a patient first organization is a huge draw.  Families see that we truly care."<br /> - Spark Teammate</p>
                        <div className="arrow" />
                    </div>
                    <div className="image">
                        <div className="back-bub-7" />
                        <div className="back-bub-8" />
                        <img src={join3} alt="Spark Home Health | Man teaching boy" />
                    </div>
                </div>
            </div>
            <div className="legalize">
                <p>Spark Home Health does not discriminate on the basis of race, color, religion (creed), gender, gender expression, age, national origin (ancestry), disability. We are committed to providing an inclusive and welcoming environment for all members of our staff, clients, volunteers, subcontractors, vendors, and patients.</p>
            </div>
        </div>
    )
}

export default JoinOurTeam