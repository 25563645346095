import TMBubbles from '../../BackgroundBubbles/ServicePagesBubbles/TMBubbles'
import telemedicine1 from '../../../assets/images/services/telemedicine/telemedicine.png'
import telemedicine2 from '../../../assets/images/services/telemedicine/telemedicine2.png'
import robin from '../../../assets/images/services/telemedicine/robin.png'

const TM: React.FC = () => {
    return (
        <div className="tm">
            <TMBubbles />
            <div className="tm__top">
                <div className="image-bubbles">
                    <div className="back-bub-1" />
                    <div className="back-bub-2" />
                    <div className="bubble-1">
                        <img src={telemedicine1} alt="Spark Home Health | Telemedicine" />
                    </div>
                </div>
                <div className="tm__about-tm">
                    <div className="box">
                        <h1>What Are Telemedicine Services?</h1>
                        <p>Spark Home Health provides exemplary, evidence-based telemedicine services utilizing HIPAA compliant live-video visits. Our clinicians provide skilled evaluation and assessments, direct therapeutic interventions, education, coaching and care team collaboration via telemedicine. Telemedicine is an invaluable tool to improve patient progress toward individualized goals.</p>
                    </div>
                    <div className="box box2">
                        <h1>Who Can Use Telemedicine Services?</h1>
                        <p>Colorado currently allows for all home healthcare services to be conducted via telemedicine if medically necessary and ordered by a physician.</p>
                    </div>
                </div>
            </div>
            <div className="tm__mid-bottom">
                <div className="tm__about-tm">
                    <div className="box">
                        <h1>What Are the Benefits of Telemedicine?</h1>
                        <ul>
                            <li><strong>Improved Carry-Over of Skills-</strong> Because the clinician isn’t present in the home, patients and caregivers demonstrate ability to complete activities and exercises without the clinician’s hands-on facilitation. This type of engagement encourages mastery of specific skills and increases the likelihood the patient and caregiver integrate that skill into the weekly routine.</li>
                            <li><strong>Reduction in Missed Visits and Improved Safety-</strong> Utilizing telemedicine during illness or inclement weather helps to ensure every important visit is completed-- leading to overall improved outcomes!</li>
                            <li><strong>Increased Access to Services-</strong> Telemedicine allows greater access to clinicians who possess extensive expertise in an area such as  feeding, sensory development, autism or Augmentative and Assistive Communication (AAC).</li>
                        </ul>
                    </div>
                </div>
                <div className="image-bubbles">
                    <div className="back-bub-3" />
                    <div className="back-bub-4" />
                    <div className="back-bub-5" />
                    <div className="bubble-1">
                        <img src={telemedicine2} alt="Spark Home Health | Telemedicine | Service" />
                    </div>
                    <div className="quote-bubble">
                        <p>"I love the personal experience that staff and families get from a local, close-knit company"<br /> - Spark Therapist</p>
                        <div className="arrow" />
                    </div>
                </div>
            </div>
            <div className="tm__bottom">
                <div className="link-container">
                    <a href="https://www.goodmorningamerica.com/wellness/video/physical-occupational-therapy-home-70282191" target="_blank">
                        <div className="image">
                            <img src={robin} alt="Spark Home Health | Robin Roberts Interview | Telemedicine" />
                        </div>
                        <div className="box">
                            <p>Click here to view Spark Home Health Clinical Manager Christine Robenalt, PT discuss telemedicine services with Good Morning America’s Robin Roberts</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default TM