interface resourceProps {
    currentPages: any,
}

const ResourceMobileRender: React.FC<resourceProps> = (props) => {

    return (
        <div className="resource-mobile-render">
            {
                props.currentPages.map((resource: any, idx) => {
                    return (
                        <div key={idx} className="mobile-page-content">
                            {
                                !idx ?
                                <h1>{resource.title}</h1>
                                :
                                null
                            }
                            <div className="mobile-page__section">
                                {
                                    resource.specialHeader ?
                                    <h3>{resource.specialHeader}</h3>
                                    :
                                    null
                                }
                                {
                                    resource.header ?
                                    <h2>{resource.header}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.body ?
                                    <p>{resource.body}</p>
                                    :
                                    null
                                }
                                {
                                    resource.link ?
                                    <p>Website:<a href={resource.link} target="_blank">{resource.link}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.specialHeader2 ?
                                    <h3>{resource.specialHeader2}</h3>
                                    :
                                    null
                                }
                                {
                                    resource.header2 ?
                                    <h2>{resource.header2}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.body2 ?
                                    <p>{resource.body2}</p>
                                    :
                                    null
                                }
                                {
                                    resource.link2 ?
                                    <p>Website:<a href={resource.link2} target="_blank">{resource.link2}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.specialHeader3 ?
                                    <h3>{resource.specialHeader3}</h3>
                                    :
                                    null
                                }
                                {
                                    resource.header3 ?
                                    <h2>{resource.header3}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.body3 ?
                                    <p>{resource.body3}</p>
                                    :
                                    null
                                }
                                {
                                    resource.link3 ?
                                    <p>Website:<a href={resource.link3} target="_blank">{resource.link3}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.specialHeader4 ?
                                    <h3>{resource.specialHeader4}</h3>
                                    :
                                    null
                                }
                                {
                                    resource.header4 ?
                                    <h2>{resource.header4}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.body4 ?
                                    <p>{resource.body4}</p>
                                    :
                                    null
                                }
                                {
                                    resource.link4 ?
                                    <p>Website:<a href={resource.link4} target="_blank">{resource.link4}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.header5 ?
                                    <h2>{resource.header5}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.body5 ?
                                    <p>{resource.body5}</p>
                                    :
                                    null
                                }
                                {
                                    resource.link5 ?
                                    <p>Website:<a href={resource.link5} target="_blank">{resource.link5}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.header6 ?
                                    <h2>{resource.header6}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.body6 ?
                                    <p>{resource.body6}</p>
                                    :
                                    null
                                }
                                {
                                    resource.link6 ?
                                    <p>Website:<a href={resource.link6} target="_blank">{resource.link6}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.header7 ?
                                    <h2>{resource.header7}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.body7 ?
                                    <p>{resource.body7}</p>
                                    :
                                    null

                                }
                                {
                                    resource.link7 ?
                                    <p>Website:<a href={resource.link7} target="_blank">{resource.link7}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.header8 ?
                                    <h2>{resource.header8}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.link8 ?
                                    <p>Website:<a href={resource.link8} target="_blank">{resource.link8}</a></p>
                                    :
                                    null
                                }
                            </div>
                            <div className="mobile-page__section">
                                {
                                    resource.header9 ?
                                    <h2>{resource.header9}</h2>
                                    :
                                    null
                                }
                                {
                                    resource.link9 ?
                                    <p>Website:<a href={resource.link9} target="_blank">{resource.link9}</a></p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ResourceMobileRender