import { useState } from 'react'
import { useAuth } from '../../Firebase/Firebase'
import {  useHistory } from 'react-router-dom'
import AdminBubbles from '../../BackgroundBubbles/logins/EmployeeLoginBubbles'
import { getFirebaseErrorMessage } from '../../../const/utilities'

const AdminLogin: React.FC = () => {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ error, setError ] = useState('')

    const auth = useAuth()
    const history = useHistory()

    const clearInputs = () => {
        setEmail('')
        setPassword('')
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
          await auth.signin(email, password)
          history.push('/admin-portal')
        } catch(error) {
            setError(getFirebaseErrorMessage(error.code))
            clearInputs()
        }
    }

    return (
        <div className="admin-login">
            <AdminBubbles />
            <div className="admin-login__inner">
                <form>
                    {
                        error !== '' ?
                        <p className="error-msg">{error}</p>
                        :
                        null
                    }
                    <label htmlFor="email">Email</label>
                    <input
                        id="email" 
                        type="text" 
                        required 
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                    />
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        type="password"
                        required
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <button type="submit" onClick={onSubmit}>Sign In</button>
                </form>
            </div>
        </div>
    )
}

export default AdminLogin