import { useState } from 'react'
import { useAuth } from '../../../Firebase/Firebase'
import {  useHistory } from 'react-router-dom'
import employee from '../../../../assets/images/logins/employee-login.png'
import PatientLoginBubbles from '../../../BackgroundBubbles/logins/PatientLoginBubbles'
import lock from '../../../../assets/images/logins/lock.png'
import { getFirebaseErrorMessage } from '../../../../const/utilities'

const EmployeeLogin: React.FC = () => {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ error, setError ] = useState('')

    const auth = useAuth()
    const history = useHistory()

    const clearInputs = () => {
        setEmail('')
        setPassword('')
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        try {
          await auth.signin(email, password)
          history.push('/employee-portal')
          console.log(auth.user)
        } catch(error) {
            setError(getFirebaseErrorMessage(error.code))
            clearInputs()
        }
    }

    return (
        <div className="employee-login">
            <PatientLoginBubbles />
            <div className="image">
                <div className="back-bub-1" />
                <div className="back-bub-2" />
                <img src={employee} alt="Spark Home Health | Employee Login | Denver, CO" />
            </div>
            <div className="quote-bubble">
                <p>"The fact we are a patient first organization is a huge draw.  Families see that we truly care."<br /> - Spark Therapist</p>
                <div className="arrow" />
            </div>
            <div className="employee-login__inner">
                <form>
                    {
                        error !== '' ?
                        <p className="error-msg">{error}</p>
                        :
                        null
                    }
                    <div className="containers">
                        {/* <div className="icon">
                            <img src={lock} alt="Spark Home Health | Login | Password" />
                        </div> */}
                        <label htmlFor="email">Email</label>
                        <input 
                            id="email"
                            type="text" 
                            required 
                            value={email} 
                            onChange={e => setEmail(e.target.value.toLowerCase())} 
                        />
                    </div>
                    <div className="containers">
                        {/* <div className="icon">
                            <img src={lock} alt="Spark Home Health | Login | Password" />
                        </div> */}
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            type="password"
                            required
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <button type="submit" onClick={onSubmit}>Sign In</button>
                </form>
                <p>Having trouble logging in?<br /> Call <a href="tel:720-479-8952" target="_blank">720-479-8952</a> during normal business hours.</p>
            </div>
        </div>
    )
}

export default EmployeeLogin