import * as ERROR_MESSAGES from './error-handling/customErrorMessages'

export const collectIdsAndDocs = doc => { return { id: doc.id, ...doc.data() }}

export const getFirebaseErrorMessage = code => {
    let message: any = null

    switch (code) {
        case "auth/user-not-found":
            message = ERROR_MESSAGES.USER_NOT_FOUND
            break
        case "auth/invalid-email":
            message = ERROR_MESSAGES.INVALID_EMAIL_FORMAT
            break
        case "auth/wrong-password":
            message = ERROR_MESSAGES.INVALID_PASSWORD_FORMAT
            break
        case "auth/email-already-exists":
            message = ERROR_MESSAGES.EMAIL_ALREADY_EXIST
            break
        default:
            message = ERROR_MESSAGES.DEFAULT_MESSAGE
            break
    }
    return message
}