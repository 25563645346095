import childEducation from '../assets/Documents/portals/resources/child-education-and-learning.pdf'
import childToAdult from '../assets/Documents/portals/resources/Child to Adult Transition Programs.pdf'
import communityCentered from '../assets/Documents/portals/resources/Community Centered Boards and Early Intervention.pdf'
import diagnosisSpecific from '../assets/Documents/portals/resources/Diagnosis Specific Support.pdf'
import domesticViolence from '../assets/Documents/portals/resources/Domestic Violence and Child Abuse.pdf'
import equiptmentAdaptive from '../assets/Documents/portals/resources/Equipment and Adaptive Toys.pdf'
import financialPrograms from '../assets/Documents/portals/resources/Financial Assistance Programs.pdf'
import general from '../assets/Documents/portals/resources/General Family Services.pdf'
import healthServices from '../assets/Documents/portals/resources/Health Services.pdf'
import housingFinancial from '../assets/Documents/portals/resources/Housing Financial Assistance Programs.pdf'
import legal from '../assets/Documents/portals/resources/Legal Services.pdf'
import nursingResources from '../assets/Documents/portals/resources/Nursing Mothers Resources.pdf'
import recreation from '../assets/Documents/portals/resources/Recreational Resources.pdf'
import respiteCare from '../assets/Documents/portals/resources/Respite Care.pdf'
import transportation from '../assets/Documents/portals/resources/Transportation Services.pdf'
import pdf from '../assets/images/portals/pdf.png'

export const resources = [
    {
        TOC: 'Child Education and Learning (p. 2)',
        title: 'Child Education and Learning',
        header: 'English Language Services',
        link: 'https://www.denverlibrary.org/content/learn-english',
        header2: 'Learning Differences World',
        body2: "Supporting families in working together toensure that the school is meeting the needs of your child",
        link2: 'https://learningdifferencesworld.com/',
        header3: 'Colorado Dept. of Education',
        body3: "CDE serves students, parents, and the general public by protecting the public trust throughensuring adherence to laws, strong stewardship of public funds, and accountability for student performance",
        link3: 'http://www.cde.state.co.us/',
        header4: 'GoldStar Learning Options',
        body4: "Supporting and advocating for individuals with intellectual and developmental differences, and their families, in order to maximize their strengths and abilities, so that they may achieve their greatest potential",
        link4: 'https://www.gsloinc.com/',
        pdf: childEducation,
        number: 2,
    },
    {
        TOC: 'Child to Adult Transition Programs (pp. 3-4)',
        title: 'Child to Adult Transition Programs',
        header: 'Ascendigo',
        body: 'Our mission is to elevate the spectrum by empowering people, inspiring lives, and shattering expectations',
        link: 'https://www.ascendigo.org/',
        header2: 'Bridges of Colorado',
        body2: "To provide the services necessary to allow individuals with disabilities, with the support of their families and friends, to live, learn and work where they choose within the community",
        link2: 'https://colorado.bridges.us/',
        header3: 'Community Learning Alternatives',
        body3: "To provide exceptional & comprehensive programming for young people with autism, their families & communities.",
        link3: 'https://gardenautism.org/',
        header4: 'Continuum of Colorado',
        body4: "A partner in choice and independence by collaborating with individuals and their families to provide high-quality lifetime supports, empowering them to thrive in their community.",
        link4: 'https://www.continuumcolo.org/',
        pdf: childToAdult,
        number: 3
    },
    {
        
        title: 'Child to Adult Transition Programs',
        header: 'High Pointe Perspective',
        body: 'We focus on personalizing care and adapting our services to meet each individual need',
        link: 'https://www.highpointeservices.org/',
        header2: 'Independent Living Experience',
        body2: "Provides a flexible continuum of supports addressing each adult’s need to build and maintain their own circle of support.",
        link2: 'https://independentlivingexperience.com/',
        header3: 'Steppingstone Support Center',
        body3: "Dedicated to sharing opportunities to become the best version of ourselves.",
        link3: 'https://steppingstonesupportcenter.com/',
        header4: 'Wayfaring Band',
        body4: "To foster inclusion, interdependence, and genuine connection between people with and without intellectual and developmental disabilities by crafting original, transformative adventures for a neurodiverse band of travelers.",
        link4: 'https://www.thewayfaringband.com/',
        number: 4
    },
    {
        TOC: 'Community Centered Boards and Early Intervention (EI) (p. 5)',
        title: 'Community Centered Boards and Early Intervention (EI)',
        body: "NOTE: Community Center Boards serve all of Colorado to find your closest one please click here:",
        link: 'https://www.colorado.gov/pacific/hcpf/community-centered-boards',
        header2: 'EI Colorado- Early Intervention Colorado',
        body2: 'Provides supports and services to children with developmental delays or disabilities and their families from birth until the child’s third birthday. Early Intervention Colorado can help families learn ways to support and promote their child’s development within their everyday routines and activities.',
        link2: 'http://eicolorado.org',
        header3: 'Community Centered Boards and Early Intervention',
        body3: 'Community Centered Boards (CCB) Case Management Services assist a person in accessing necessary services and supports to meet his or her needs. Services include intake, eligibility determination, service plan development, arrangement for services, delivery of services, service and support coordination, monitoring, any safeguards necessary to prevent conflict of interest between case management and direct service provision, and termination and discharge from',
        link3: 'https://www.colorado.gov/pacific/hcpf/community-centered-boards',
        pdf: communityCentered,
        number: 5
    },
    {
        TOC: 'Diagnosis Specific Support (pp. 6-7)',
        title: 'Diagnosis Specific Support',
        header: 'Muscular Dystrophy Association',
        link: 'https://www.mda.org/',
        header2: 'Epilepsy Foundation of Colorado',
        link2: 'https://www.epilepsycolorado.org/',
        header3: 'Autism Society of Colorado',
        link3: 'https://www.autismcolorado.org/',
        header4: 'Brain Injury Alliance of Colorado',
        link4: 'https://biacolorado.org/',
        header5: 'Colorado Cross-Disability Coalition',
        link5: 'https://www.ccdconline.org/',
        header6: 'Colorado Spina Bifida Association',
        link6: 'http://www.coloradospinabifida.org/',
        header7: 'KID Foundation (Sensory Processing Disorders)',
        link7: 'http://www.kidfoundation.org/',
        header8: 'Mile Hi Down Syndrome Association',
        link8: 'http://www.mhdsa.org/',
        header9: 'Rocky Mountain Down Syndrome Association',
        link9: 'https://www.rmdsa.org/',
        pdf: diagnosisSpecific,
        number: 6
    },
    {
        title: 'Diagnosis Specific Support',
        header: 'Stuttering Foundation',
        link: 'https://www.stutteringhelp.org/',
        header2: 'Feeding Matters',
        link2: 'https://www.feedingmatters.org/',
        header3: 'National Swallowing Disorders Foundation',
        link3: 'https://swallowingdisorderfoundation.com/medical-conditions/infants-and-children/',
        header4: 'Apraxia Kids',
        link4: 'https://www.apraxia-kids.org/',
        header5: 'Autism Speaks',
        link5: 'https://www.autismspeaks.org/',
        header6: 'Sensory Processing Disorders Foundation',
        link6: 'https://www.spdfoundation.net/',
        header7: 'Cerebral Palsy Foundation',
        link7: 'https://www.yourcpf.org/',
        number: 7
    },
    {
        TOC: 'Domestic Violence and Child Abuse (pp. 8-9)',
        title: 'Domestic Violence and Child Abuse',
        body: <>NOTE: If you have an immediate question or concern about family violence, consult the Child Help National Child Abuse Hotline at <a href="tel:800-422-4453">800-422-4453</a> or the National Domestic Abuse Violence Hotline at <a href="tel:800-799-7233">800-799-7233</a>.</>,
        header2: 'Child Help National Child Abuse Hotline',
        body2: "Childhelp National Child Abuse Hotline website. The hotline's website offers resources that include a local child protective services lookup and downloadable posters, in English and Spanish, that emphasize the importance of reporting",
        link2: 'https://www.childhelp.org/hotline/',
        header3: 'National Domestic Violence Hotline',
        body3: "National Domestic Violence Hotline website. In addition to presenting multiple ways to contact the hotline, the website includes information that helps visitors identify abuse, learn about health relationships, and access more resources.",
        link3: 'https://www.thehotline.org/',
        header4: 'American Professional Society',
        body4: "American Professional Society on the Abuse of Children. Website of the national organization focused on meeting the needs of professionals engaged in all aspects of services for maltreated children and their families.",
        link4: 'https://www.apsac.org/',
        pdf: domesticViolence,
        number: 8
    },
    {
        title: 'Domestic Violence and Child Abuse',
        header: 'Women’s Health',
        body: "Domestic or Intimate Partner Violence: Womenshealth.gov. From the U.S. Department of Health and Human Services, a wealth of information and resources that include guidance on getting a restraining order, leaving an abusive relationship, and effects on children.",
        link: 'https://www.womenshealth.gov/relationships-and-safety/domestic-violence',
        number: 9
    },
    {
        TOC: 'Equipment and Adaptive Toys (pp. 10-11)',
        title: 'Equipment and Adaptive Toys',
        header: 'Colorado Talking Book Library',
        body: 'For people who can\'t read standard print, CTBL provides audio, Braille and large print books keeping you connected to reading, stories and adventure.',
        link: 'https://myctbl.cde.state.co.us/',
        header2: 'Home Builders Foundation',
        body2: "To build independence, provide opportunities and elevate lives for individuals and families with disabilities in our community",
        link2: 'https://hbfdenver.org/',
        header3: 'Kids Mobility Network',
        body3: "Providing children with disabilities with durable medical equipment such as wheelchairs, walkers and other medical equipment",
        link3: 'http://www.kidsmobility.org/',
        header4: 'NuMotion',
        body4: "Provides a wide spectrum of mobility needs to our customers",
        link4: 'https://www.numotion.com/',
        header5: 'Fun and Function',
        body5: "Creating the best sensory toys and tools on the planet",
        link5: 'https://funandfunction.com/',
        pdf: equiptmentAdaptive,
        number: 10
    },
    {
        title: 'Equipment and Adaptive Toys',
        header: 'Major Medical',
        link: 'https://www.majormed.com/',
        header2: 'Accessible Systems',
        link2: 'https://www.accessiblemed.com/',
        header3: 'Max Mods',
        body3: "Founded to assist people with physical limitations with modifications and adaptions of toys and devices",
        link3: 'https://www.maxmods.org/',
        header4: 'TFH Special Needs Toys',
        link4: 'https://specialneedstoys.com/usa',
        header5: 'Two Angels Foundation Inc.',
        body5: "The mission of the Two Angels Foundation is increasing the recreational activities for children living in Colorado with physical disabilities, inspiring them to lead the most inclusive and active lives possible.",
        link5: 'https://twoangelsfoundation.org/',
        number: 11
    },
    {
        TOC: 'Financial Assistance Programs (pp. 12-15)',
        title: 'Financial Assistance Programs',
        specialHeader: 'General Financial Resources',
        header: 'Clothes to Kids',
        body: 'Provide new and quality used clothing to students from low-income or in-crisis families in the Denver Metro Area, free of charge.',
        link: 'https://clothestokidsdenver.org/',
        header2: 'Colorado PEAK (Program Eligibility and Application Kit)',
        body2: 'An online service for Coloradans to screen and apply for medical, food, cash, and childcare assistance programs.',
        link2: 'https://coloradopeak.secure.force.com/ABWEL',
        header3: 'Hospital Assistance Payment Programs',
        body3: "Work with your hospital directly to determine if you qualify for payment assistance programs.",
        link3: 'https://cohealthinitiative.org/articles/hospital-payment-assistance-program-becomes-law/',
        header4: 'Single Mothers Grants',
        body4: 'We offer largest directory of single mother grants that help with rent, utility bills, child care, education, medication, housing, medical bills, and mortgage, among others.',
        link4: 'http://singlemothersgrants.org/single-mothers-assistance-in-colorado/',
        pdf: financialPrograms,
        number: 12
    },
    {
        title: 'Financial Assistance Programs',
        specialHeader: 'Utility Assistance',
        header: '2-1-1 National Support Hotline',
        body: 'Assistance with food, shelter, rental assistance, utility assistance, childcare Phone: 2-1-1',
        link: 'https://unitedwaydenver.org/2-1-1/',
        header2: 'Xcel Energy: Excel Energy Qualified Weatherization Program',
        body2: <>Provides low-income customers in Colorado, with a focus on seniors, disabled and families with children, free energy saving enhancements to their home or apartment Phone: <a href="tel:1-800-895-4999">1-800-895-4999</a></>,
        link2: 'https://www.xcelenergy.com/programs_and_rebates',
        header3: 'Low-Income Energy Assistance Program (LEAP):',
        body3: <>Federally funded program that helps individuals pay a portion of their winter home heating costs Phone: 866-HEAT-HELP (<a href="tel:866-432-8435">866-432-8435</a>) Website: Website/Apply online for LEAP Energy Outreach Colorado Phone: <a href="tel:(303)-825-8750">(303)-825-8750</a></>,
        link3: 'https://www.energyoutreach.org/',
        header4: 'General Food or Cash Assistance Questions',
        body4: <>Assistance provided through the Colorado Department of Human Services Call Center Phone: <a href="tel:1-800-536-5298">1-800-536-5298</a></>,
        number: 13
    },
    {
        title: 'Financial Assistance Programs',
        specialHeader: 'Food Assistance',
        header: 'Food Bank Of The Rockies',
        body: 'We help families thrive by efficiently procuring and distributing food and essentials to the hungry through our programs and partner agencies.',
        link: 'https://www.foodbankrockies.org/',
        header2: 'Catholic Charities',
        link2: 'https://ccdenver.org/',
        header3: 'Food Pantries List',
        body3: 'A list of food pantries in the Denver Metro Area',
        link3: 'https://www.foodpantries.org/ci/co-denvers',
        specialHeader4: 'Internet and Telecom Resource',
        header4: 'Spectrum Internet Assist',
        link4: 'https://www.spectrum.com/internet/spectrum-internet-assist?opredirect=browse-content-spectrum-internet-assist',
        header5: 'StandUp Lifeline Wireless – Free Wireless Service',
        link5: 'https://standupwireless.com/',
        header6: 'Internet Essentials by: Comcast',
        link6: 'https://apply.internetessentials.com/',
        header7: 'Lifeline Program for Low-Income Consumer',
        link7: 'https://www.lifelinesupport.org/',
        number: 14
    },
    {
        title: 'Financial Assistance Programs',
        header: 'Q Link’s Lifeline Program',
        link: 'https://qlinkwireless.com/members/lifeline-eligibility.aspx',
        header2: 'The Lifeline Program',
        link2: 'https://www.accesswireless.com/lifeline',
        header3: 'Lifeline Phone Program',
        link3: 'https://www.assurancewireless.com/',
        number: 15
    },
    {
        TOC: 'General Family Services (pp. 16-19)',
        title: 'General Family Services',
        header: 'Ability Connections Colorado',
        body: 'Provides inclusive education, pathways to employment, and statewide family support programs to create opportunities and sustainable change for families across Colorado.',
        link: 'https://www.abilityconnectioncolorado.org/',
        header2: 'The ARC',
        body2: 'Promotes and protects the human rights of people with intellectual and developmental disabilities and actively supports their full inclusion and participation in the community throughout their lifetimes.',
        link2: 'https://thearcofco.org/',
        header3: 'Autism Community Store',
        body3: "Help families, teachers, therapists and autistics get hard-to-find products for individuals with autism, sensory issues, ADHD and other special needs at reasonable prices.",
        link3: 'https://www.autismcommunitystore.com/',
        header4: 'Car Seat Information',
        link4: 'https://www.healthychildren.org/English/safety-prevention/on-the-go/Pages/Car-Safety-Seats-Information-for-Families.aspx',
        pdf: general,
        number: 16
    },
    {
        title: 'General Family Services',
        header: 'CDC Developmental Milestones',
        body: 'The Centers for Disease Control and Prevention’s (CDC) Developmental Milestones Tracker is a useful guide to the things most children can do by a certain age. Click on the ages below to view the CDC’s associated developmental milestones:',
        link: 'https://www.cdc.gov/ncbddd/actearly/milestones/index.html',
        header2: 'Maria Droste Counseling Center',
        body2: 'Our goal is to create a community mental health center where all Coloradans feel welcome and safe.',
        link2: 'https://mariadroste.org/',
        header3: 'Parents Encouraging Parents',
        body3: "Parents Encouraging Parents are non-specific disability conferences.",
        link3: 'https://www.cde.state.co.us/cdesped/pep',
        header4: 'Peak Parent Center',
        body4: 'Ensuring that people with all types of disabilities can be fully included in their neighborhood schools, their communities, and in all walks of life.',
        link4: 'http://www.peakparent.org/',
        header5: 'STAR Center',
        body5: 'Impact quality of life by developing and promoting best practices for sensory health and wellness through treatment, education, and research.',
        link5: 'https://sensoryhealth.org/',
        number: 17
    },
    {
        title: 'General Family Services',
        header: 'Stout Street Clinic',
        body: 'Provide the necessary services and support in a totally structured therapeutic community environment to assist addicts and alcoholics to help themselves in rehabilitation, recovery, and transition in returning to society as productive and responsible citizens.',
        link: 'https://www.stoutstreet.org/',
        header2: 'The Thrive Center',
        body2: 'Provide parents with information and training about disabilities',
        link2: 'https://www.thrivectr.org/',
        header3: 'Safe Kids',
        body3: "The mission is to prevent childhood injuries.",
        link3: 'https://www.safekids.org/',
        header4: 'El Grupo Vida',
        body4: 'Empowering people with disabilities and their families with information and training to help them become their best advocate',
        link4: 'https://elgrupovida.org/Index.htm',
        header5: 'Family Voices',
        body5: 'Making Colorado a better place for children with special health care needs.',
        link5: 'https://familyvoicesco.org/contact-us/',
        number: 18
    },
    {
        title: 'General Family Services',
        header: 'Hands and Voices',
        body: 'Parent-driven organization dedicated to supporting families of children who are deaf or hard of hearing',
        link: 'https://handsandvoices.org/index.htm',
        header2: 'Health Care Program for Children with Special Needs',
        body2: 'Nurse-led team providing care coordination for children and youth with special health care needs from birth to 21',
        link2: 'https://cdphe.colorado.gov/prevention-and-wellness/hcp-a-program-for-children-and-youth-with-special-health-care-needs',
        header3: 'JFK Partners',
        body3: "Promotes the individuality, inclusion, active community engagement, health, and well- being of all people of diverse backgrounds with Intellectual/Developmental Disabilities and their families.",
        link3: 'https://medschool.cuanschutz.edu/jfk-partners',
        header4: 'Zero to Three',
        body4: 'Our mission is to ensure that all babies and toddlers have a strong start in life. At ZERO TO THREE, we envision a society that has the knowledge and will to support all infants and toddlers in reaching their full potential.',
        link4: 'https://www.zerotothree.org/',
        number: 19
    },
    {
        TOC: 'Health Services (pp. 20-25)',
        title: 'Health Services',
        specialHeader: 'Vision Services',
        header: 'Anchor Center for Blind Children',
        body: 'Provides early education and intervention services for children birth to five years old who are blind or visually impaired.',
        link: 'https://anchorcenter.org/',
        header2: 'A Shared Vision',
        body2: 'to inspire and empower families to nurture the development of their very young children who are blind or visually impaired so that all children may discover their brightest future',
        link2: 'https://www.asharedvision.org/',
        specialHeader3: 'Orthotics',
        header3: 'Orthotic Prosthetic Solutions',
        link3: 'Website: http://www.opscolorado.com',
        header4: 'DAPO: Dynamic Advantage Prosthetics & Orthotics, LLC',
        body4: <>Phone: <a href="tel:719-299-1692">719.299.1692</a>; Fax: 800.797.1809</>,
        header5: 'Hanger Clinic',
        link5: 'https://hangerclinic.com/clinics/CO/parker/cottonwood-dr/',
        header6: 'Creative Technology Orthotics and Prosthetics',
        body6: <>Phone: <a href="tel:303-346-1906">303-346-1906</a></>,
        link6: 'https://ctechops.net/',
        header7: 'Children’s Hospital Prosthetic & Orthotic Group',
        link7: "https://p-o-group.com/",
        pdf: healthServices,
        number: 20
    },
    {
        title: 'Health Services',
        specialHeader: 'Homecare (CNA)',
        header: 'Team Select Home Care',
        link: 'https://teamselecthh.com/',
        header2: 'MGA Academy',
        link2: 'https://mgahomecare.com/mga-academy/',
        header3: 'PASCO',
        link3: 'https://pascohh.com/',
        number: 21
    },
    {
        title: 'Health Services',
        specialHeader: 'Hospitals and In-Home Urgent Care',
        header: 'The Children\'s Hospital',
        link: 'http://www.thechildrenshospital.org/',
        header2: 'Rocky Mountain Hospital for Children',
        link2: 'https://rockymountainhospitalforchildren.com/',
        header3: 'Pediatric Direct Care',
        body3: <><a href="tel:720-437-0386" target="_blank">720-437-0386</a></>,
        link3: "http//www.pediatricdirectcare.com",
        specialHeader4: 'Mental Health Resources',
        header4: 'Mental Health Center of Denver (MHCD)',
        link4: "https://mhcd.org/",
        header5: 'Arapahoe-Douglas County Mental Health Network',
        link5: 'https://www.allhealthnetwork.org/',
        header6: 'Aurora Mental Health Center',
        body6: <>AMH’s mission is to promote individual, family and community wellness by providing exceptional, compassionate, responsive, inclusive and integrated behavioral health care. Phone: <a href="tel:303-793-9603">303-793-9603</a></>,
        header7: 'Centennial Mental Health Center, INC',
        body7: <>CMHC is a nonprofit organization dedicated to providing the highest quality comprehensive mental health services to the rural communities of northeastern Colorado. Phone: <a href="tel:970-522-4392">970-522-4392</a></>, 
        number: 22
    },
    {
        title: 'Health Services',
        specialHeader: 'Autism Resources',
        header: 'Autism Home Support Services',
        link: 'https://autismhomesupport.com/',
        header2: 'Spectra Autism Center',
        body2: 'To bring forward the unique talents, abilities and personalities of individuals with neurodevelopmental disorders and other psychological needs so they can lead meaningful and rewarding lives',
        link2: 'https://www.spectracenters.org/',
        header3: 'Firefly Autism',
        body3: 'To transform the lives of children with autism. Partnering with families, we create life-long relationships through thoughtful, innovative, empirical learning treatment programs.',
        link3: "https://fireflyautism.org/",
        header4: 'Garden Autism Services',
        body4: 'To provide exceptional & comprehensive programming for young people with autism, their families & communities',
        link4: 'http://www.gardencolorado.org/cgi-sys/defaultwebpage.cgi',
        header5: 'Legacy Center',
        link5: 'http://legacyparker.com/',
        header6: 'JumpStart Autism Center',
        link6: 'https://jumpstartaba.com/',
        number: 23
    },
    {
        title: 'Health Services',
        header: 'Trumpet Behavioral Health',
        body: 'Trumpet Behavioral Health is a national autism treatment provider that works with children with autism spectrum disorder, social communication disorders and developmental delays to help them become happy and productive members of their families, communities, and world.',
        link: 'https://tbh.com/',
        header2: 'The Children’s Hospital Colorado',
        link2: 'https://www.childrenscolorado.org/conditions-and-advice/conditions-and-symptoms/conditions/autism-spectrum-disorders/',
        header3: 'Rocky Mountain Human Services Children\'s Clinical Services Team',
        body3: 'Rocky Mountain Human Services is a nonprofit organization that empowers people with the resources they need to thrive, and to live the lives they envision in their communities of choice.',
        link3: "https://www.rmhumanservices.org/our-services",
        header4: 'DevelopmentalFx',
        link4: 'https://developmentalfx.org/',
        number: 24
    },
    {
        title: 'Health Services',
        specialHeader: 'Colorado Medicaid/Department of Human Services',
        header: 'Colorado Medicaid:',
        body: <>Information on both income or disability based and buy-in program Phone: <a href="tel:1-800-221-3943">1-800-221-3943</a> | State Relay: 711</>,
        link: 'https://www.healthfirstcolorado.com/',
        header2: 'Colorado Department of Human Services:',
        link2: 'https://cdhs.colorado.gov/',
        header3: 'Waivers/Programs for individuals with disabilities:',
        body3: 'Brain Injury Waiver (BI), Children with Life Limiting Illness Waiver (CLS), Children’s Extensive Support Waiver: (CES), Children’s Habilitation Residential Program Waiver (CHRP), Children’s Home and Community-Based Services Waiver (CHCBS), Family Support Services Program (FSSP)',
        link3: "https://www.colorado.gov/pacific/hcpf/programs-individuals-physical-or-developmental-disabilities",
        number: 25
    },
    {
        TOC: 'Housing Financial Assistance Program (pp. 26-45)',
        title: 'Housing Financial Assistance Program',
        header: '2-1-1 Colorado',
        body: '2-1-1 Colorado is a collaborative of eight organizations, hosting six call centers across the state to provide food, shelter and rent assistance to those who qualify. Simply dial 211.',
        header2: 'Colorado Department of Local Affairs (Division of Housing)',
        body2: <>CDLA offers rental assistance statewide through the Homelessness Prevention and Rapid Rehousing Program. Phone: <a href="tel:303-864-7810">303-864-7810</a></>,
        header3: 'Colorado Homeless Families',
        body3: <>Colorado Homeless Families is a nonprofit that focuses on transitional housing and provides a road to self-sufficiency. Phone: <a href="tel:303-420-6634">303-420-6634</a></>,
        header4: 'Colorado Homeless Prevention Activities',
        body4: <>CHPA provides emergency rental assistance to low-income and/or struggling households who are at risk of facing eviction or who are at risk of losing their homes without community or government assistance. Phone: <a href="tel:303-866-4921">303-866-4921</a></>,
        header5: 'Colorado Housing Assistance Corporation',
        body5: <>CHAC’s mission is to help make housing and successful home ownership affordable to low-income people. Phone: <a href="tel:303-572-9445">303-572-9445</a></>,
        header6: 'Colorado Rural Housing Development Corporation',
        body6: <>CRHDC provides housing assistance to those in need across Colorado. Phone: <a href="tel:303-428-1448">303-428-1448</a></>,
        pdf: housingFinancial,
        number: 26
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Elderly, Senior Citizens and Older Adults',
        body: <>Assistance programs for seniors include food, health, legal aid and job training. For more information about SCSEP, contact the U.S. Department of Labor. Phone: <a href="tel:1-877-872-5627">1-877-872-5627</a></>,
        header2: 'Hope House Colorado',
        body2: <>Hope House of Colorado empowers teenage moms to strive for personal and economic self-sufficiency and to understand their significance in God’s sight, resulting in a healthy future for them, and for their children. Phone: <a href="tel:303-429-1012">303-429-1012</a></>,
        header3: 'Mercy Housing',
        body3: <>MH seeks to create stable, vibrant and healthy communities by developing, financing and operating affordable, program-enriched housing for families, seniors and people with special needs who lack the economic resources to access quality, safe housing opportunities. Phone: <a href="tel:303-830-3300">303-830-3300</a></>,
        header4: 'Rocky Mountain Mutual Housing Association',
        body4: <>RMMHA provides attractive and affordable housing to over 1300 families in 8 locations across Colorado. Phone: <a href="tel:303-863-8651">303-863-8651</a></>,
        header5: 'The Ross Management Group',
        body5: <>RMG provides housing assistance to those who qualify. Phone: <a href="tel:303-860-7885">303-860-7885</a></>,
        number: 27
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Salvation Army',
        body: <>The Salvation Army provides short-term rental assistance and financial assistance for security deposits. They work with organizations that can provide emergency lodging and shelter. Phone: <a href="tel:303-295-3366">303-295-3366</a> (Denver), <a href="tel:719-636-3891">719-636-3891</a> (El Paso County), <a href="tel:719-543-3656">719-543-3656</a> (Pueblo), <a href="tel:970-207-4472">970-207-4472</a> (Larimer County), <a href="tel:970-945-6976">970-945-6976</a> (Garfield), <a href="tel:970-874-4840">970-874-4840</a> (Gunnison)</>,
        header2: 'Senior Housing Options, INC',
        body2: <>SHO provides and promotes quality affordable housing and services in a caring environment for older adults in Colorado. Phone: <a href="tel:303-595-4464">303-595-4464</a></>,
        header3: 'STRIDE',
        body3: <>STRIDE provides families with services and a personalized plan to attain economic independence and break the cycle of poverty. Phone: <a href="tel:303-238-3580">303-238-3580</a></>,
        header4: 'Supportive Housing Programs (SHP) Rental Assistance (formally SHHP)',
        body4: <>The Supportive Housing Program provides an effective and efficient approach to offering funds for paying rent, providing low-income housing and various other supportive services to thousands of low income Colorado families, senior citizens and vulnerable adults. Emergency rent and housing assistance is administered as a part of the federal government funded Shelter Plus Care programs as well as the Section 8 Housing Choice Voucher program. Phone: <a href="tel:303-864-7852">303-864-7852</a></>,
        number: 28
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Urban Peak',
        body: <>Urban Peak serves youth experiencing homelessness in the Denver metropolitan area and Colorado Springs. Phone: <a href="tel:303-974-2900">303-974-2900</a></>,
        header2: 'Adams Mental Health Foundation',
        body2: <>AMHF provides housing assistance to the mentally disabled. Phone: <a href="tel:303-853-3467">303-853-3467</a></>,
        header3: 'Almost Home Inc.',
        body3: <>Almost Home seeks to prevent homelessness and provide temporary and permanently affordable housing for those who are in need of assistance. Phone: <a href="tel:303-659-6199">303-659-6199</a></>,
        header4: 'Archdiocesan Family Housing',
        body4: <>Archdiocesan Housing provides affordable, service-enriched housing for individuals and families who cannot access decent housing in the broader marketplace. Phone: <a href="tel:303-830-0215">303-830-0215</a></>,
        header5: 'Archway Housing and Services',
        body5: <>The mission of Archway Housing and Services, Inc. is to change lives by providing housing and related supportive services that engender a safe environment and teach community skills for families with very low to moderate incomes. Phone: <a href="tel:303 863-7712">303 863-7712</a></>,
        header6: 'Aurora Housing Authority',
        body6: <>Aurora Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:720-251-2091">720-251-2091</a></>,
        number: 29
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Belmont Housing Associates',
        body: <>Belmont Housing Associates provides housing and shelter and focuses specifically on public housing facilities programs. Phone: <a href="tel:303-433-8636">303-433-8636</a></>,
        header2: 'Broadway Assistance Center',
        body2: <>Belmont Housing Associates provides housing and shelter and focuses specifically on public housing facilities programs. Phone: <a href="tel:303-893-4108">303-893-4108</a></>,
        header3: 'Brothers Redevelopment, Inc.',
        body3: <>Denver-based BR is a nonprofit that provides housing and housing-related services for low-income, elderly and disabled residents. Phone: <a href="tel:303-202-6340">303-202-6340</a></>,
        header4: 'Catholic Charities',
        body4: <>Catholic Charities offers help for housing and rental needs. Phone: <a href="tel:720-377-1313">720-377-1313</a></>,
        header5: 'Community Housing Development Association',
        body5: <>The purpose of Community Housing Development Association (CHDA) is to provide quality, service supported living opportunities for people with modest means including those with developmental disabilities, mental illness and/or substance addiction disorder in the south metro Denver area. Email: <a href="mailto:jodavidson@housinglady.net">jodavidson@housinglady.net</a></>,
        number: 30
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Denver – Colorado AIDS Project',
        body: <>For HIV or AIDs patients and their families, if faced with an eviction, rental assistance maybe offered. Vouchers for motel stays, referrals to permanent housing and low-income apartments, and grants to pay a security deposit or first months’ rent may be offered. Phone: <a href="tel:303-837-0166">303-837-0166</a></>,
        header2: 'Denver Department of Human Services',
        body2: <>DDHS has financial assistance for rent for someone facing an eviction notice. Phone: <a href="tel:720-944-3666">720-944-3666</a></>,
        header3: 'Denver Housing Authority',
        body3: <>DHA’s mission is to provide safe, decent and affordable housing to Denver residents. Phone: <a href="tel:720-932-3000">720-932-3000</a></>,
        header4: 'Denver Urban Ministries (DenUM)',
        body4: <>DenUM assists with money for security deposits or rent on the first Monday of the month. Phone: <a href="tel:303-355-4896">303-355-4896</a></>,
        header5: 'Douglas County Housing Partnership',
        body5: <>The Douglas County Housing Partnership (DCHP), a multi-Jurisdictional Housing Authority, was formed in 2003 as a cooperative effort between businesses and local and county government to address the issue of the lack of affordable housing for people who work in the area. Phone: <a href="tel:303-784-7824">303-784-7824</a></>,
        header6: 'Englewood Housing Authority',
        body6: <>Provides financial assistance to those who qualify. Phone: <a href="tel:303-761-6200">303-761-6200</a></>,
        number: 31
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Enterprise Community',
        body: <>Enterprise Community creates opportunities for low- and moderate-income people through affordable housing in diverse, thriving communities. Phone: <a href="tel:303-573-1571">303-573-1571</a></>,
        header2: 'Family Homestead',
        body2: <>Family Homestead provides independent, both emergency and long-term, housing to homeless families in Denver. Phone: <a href="tel:303-623-6514">303-623-6514</a></>,
        header3: 'Family Tree',
        body3: <>Family Tree has provided innovative, life-changing services designed to end child abuse, domestic violence and homelessness. Phone: <a href="tel:303-422-2133">303-422-2133</a></>,
        header4: 'Hope Communities Colorado',
        body4: <>Hope Communities’ mission is to create diverse, healthy communities through quality, service-enriched, affordable housing. Phone: <a href="tel:303-860-7747">303-860-7747</a></>,
        header5: 'Littleton Housing Authority',
        body5: <>Provides financial assistance to those who qualify. Phone: <a href="tel:303-991-5310">303-991-5310</a></>,
        header6: 'Mental Health Center of Denver',
        body6: <>MHCD is Denver’s source for comprehensive and accessible mental health and substance abuse treatment, housing, education and employment services for adults and the leading resource for treatment for children, teens and families. Phone: <a href="tel:303-504-6640">303-504-6640</a></>,
        number: 32
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'NewGenesis',
        body: <>NewGenesis provides a stable environment, helping people get off the streets and back into society. Phone: <a href="tel:303-881-0953">303-881-0953</a></>,
        header2: 'Northeast Denver Housing Center',
        body2: <>Northeast Denver Housing Center’s mission is to create sustainable, healthy housing opportunities for underserved households. Phone: <a href="tel:303-377-3334">303-377-3334</a></>,
        header3: 'Saint Mary Magdalene',
        body3: <>Saint Mary Magdalene helps with rent and housing for those within church boundaries. Phone: <a href="tel:303-477-4533">303-477-4533</a></>,
        header4: 'Senior Homeless Initiative (FSHI)',
        body4: <>Senior Homeless Initiative applicants need to be a Senior (60+), a family with children 17 years old or younger, or homeless. Must have a documented source of income to cover monthly expenses, no felony in last 12 months and agree to drug screening and background check. Phone: <a href="tel:303-313-2440">303-313-2440</a></>,
        header5: 'St. Paul Local Assistance Ministry',
        body5: <>St. Paul Local Assistance Ministry offers referrals to emergency shelters and housing. May have limited funds to help pay rent. Phone: <a href="tel:720-274-4710">720-274-4710</a></>,
        header6: 'The Empowerment Program',
        body6: <>The Empowerment Program provides education, employment assistance, health, housing referrals and support services to women who are in disadvantaged positions due to incarceration, poverty, homelessness, HIV/AIDS infection or involvement in the criminal justice system. Phone: <a href="tel:303-320-1989">303-320-1989</a></>,
        number: 33
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'The INN Between',
        body: <>The INN Between strives to help families and individuals who are facing homelessness in our community to be self-sufficient and sustainable – to no longer be homeless. Phone: <a href="tel:303-684-0810">303-684-0810</a></>,
        header2: 'The Third Way Center',
        body2: <>The Third Way Center offers truth and hope to high risk, mentally ill, disadvantaged and often homeless adolescents, and their families. Phone: <a href="tel:303-780-9191">303-780-9191</a></>,
        header3: 'Volunteers of America – Colorado',
        body3: <>Volunteers of America – Colorado provides financial assistance to those who qualify. Phone: <a href="tel:303-297-0408">303-297-0408</a></>,
        header4: 'Warren Village',
        body4: <>Warren Village exists to help low-income single parent families achieve personal and economic self-sufficiency — and to sustain it. Phone: <a href="tel:303-321-2345">303-321-2345</a></>,
        header5: 'Billie Spielman Center',
        body5: <>The Billie Spielman Center provides free food and other emergency aid, such as housing and rent. Programs focus on Colorado Springs Westside residents. Phone: <a href="tel:719-358-8396">719-358-8396</a></>,
        header6: 'Brush Housing Authority',
        body6: <>Provides financial assistance to those who qualify. Phone: <a href="tel:970-842-5046">970-842-5046</a></>,
        number: 34
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Calhan Housing Authority',
        body: <>Provides financial assistance to those who qualify. Phone: <a href="tel:719-347-2616">719-347-2616</a></>,
        header2: 'Catholic Charities the Diocese of Pueblo',
        body2: <>Catholic Charities offers housing counseling and assistance. Phone: <a href="tel:719-544-4233">719-544-4233</a></>,
        header3: 'Centennial Mental Health Center, INC',
        body3: <>CMHC is a nonprofit organization dedicated to providing the highest quality comprehensive mental health services to the rural communities of northeastern Colorado. Phone: <a href="tel:970-522-4392">970-522-4392</a></>,
        header4: 'Colorado Springs Housing Authority',
        body4: <>The Colorado Springs Housing Authority administers Section 8 housing in partnership with public housing authorities. Phone: <a href="tel:719-387-6700">719-387-6700</a></>,
        header5: 'Colorado Springs, Economic Development Department, Housing and Community Development',
        body5: <>The Housing and Community Development Division works with local nonprofits, charities, and organizations to provide rental assistance in the El Paso and Colorado Springs areas from the Homelessness Prevention and Rapid Re-Housing Program. Phone: <a href="tel:719-385-5336">719-385-5336</a></>,
        number: 35
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Dale House Project',
        body: <>The Dale House Project is a Christian community of staff and residents committed to being together for meals, one-to-one talks, group counseling, work crews, recreation, and outings like ski trips and camping. This is what makes it a home. Phone: <a href="tel:719-471-0642">719-471-0642</a></>,
        header2: 'Eastern El Paso Community Center',
        body2: <>The El Paso Community Center is a nonprofit provides coverage to the eastern portion of the El Paso County outside city limits of Colorado Springs. Rent assistance and referrals are offered. Phone: <a href="tel:719-347-2976">719-347-2976</a></>,
        header3: 'Ecumenical Social Ministries',
        body3: <>The Ecumenical Social Ministries’ Housing Program provides services to prevent evictions with the goal of allowing families to remain in their homes. Low income, qualified clients can receive support with partial rental assistance. Phone: <a href="tel:719-636-1916">719-636-1916</a></>,
        header4: 'Housing Authority of Center',
        body4: <>Housing Authority of Center provides financial assistance to those who qualify. Phone: <a href="tel:719-754-2537">719-754-2537</a></>,
        header5: 'Housing Access',
        body5: <>The Housing Access program offers up to two months’ rent. Phone: <a href="tel:719-636-1916">719-636-1916</a></>,
        header6: 'Hugo Housing Authority',
        body6: <>Hugo Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:719-743-2174">719-743-2174</a></>,
        number: 36
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Lamar Housing Authority',
        body: <>Lamar Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:719-336-9575">719-336-9575</a></>,
        header2: 'Mercy’s Gate',
        body2: <>Mercy’s Gate is focused on El Paso Colorado and Colorado Springs residents. The partnership of local churches can help with rental and other housing expenses, such as electric bills. Free legal advice is offered for landlord-tenant issues. Phone: <a href="tel:719-593-1394">719-593-1394</a></>,
        header3: 'Pueblo Housing Authority',
        body3: <>The Pueblo Housing Authority provides assistance to low-income families living in the City of Pueblo. Phone: <a href="tel:719-544-6230">719-544-6230</a></>,
        header4: 'Silver Key',
        body4: <>Silver Key provides housing services to persons 60 and older including assistance with paying rent, home maintenance and repair. Phone:  <a href="tel:719-632-1521">719-632-1521</a></>,
        header5: 'Tri-County Housing & Community Development Corporation',
        body5: <>Tri-County Housing & Community Development Corporation serves people and communities through facilitation of quality affordable housing community projects. Phone: <a href="tel:719-263-5168">719-263-5168</a></>,
        header6: 'Tri-Lakes Cares',
        body6: <>Tri-Lakes Cares offers assistance to residents of the Tri-Lakes (Monument) area. Phone: <a href="tel:719-481-4864">719-481-4864</a></>,
        number: 37
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Trinidad Housing Authority',
        body: <>Trinidad Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:719-846-7204">719-846-7204</a></>,
        header2: 'Walt Fortman Community Center',
        body2: <>WFCC’s Family Stabilization Services (FSS) can help with paying rent and other housing expenses such as repairs and household products. Phone: <a href="tel:719-382-8515">719-382-8515</a></>,
        header3: 'Westside Cares',
        body3: <>Westside Cares offers rent assistance to qualified low-income families. Make an appointment for any type of rent or housing assistance. Phone: <a href="tel:719-389-0759">719-389-0759</a></>,
        header4: 'Wray Housing Authority',
        body4: <>Wray Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-332-4238">970-332-4238</a></>,
        header5: 'CARE Housing',
        body5: <>CARE Housing develops and manages affordable housing communities that provide supportive services to strengthen and empower families, and to build community. Phone: <a href="tel:970-282-7522">970-282-7522</a></>,
        header6: 'Crossroads Ministry of Estes Park',
        body6: <>Crossroads offers assistance, such as free food, gasoline, partial rent or utility bill, prescription medication assistance, emergency short-term housing and shelter or other emergency needs. Phone: <a href="tel:970-577-0610">970-577-0610</a></>,
        number: 38
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Eaton Housing Authority',
        body: <>Eaton Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-454-3338">970-454-3338</a></>,
        header2: 'Fort Collins Housing Authority',
        body2: <>FCHA provides information on the Section 8 Housing Choice Voucher Program. This federal government subsidized program provides rental assistance to landlords on behalf of poor and low-income families. Phone: <a href="tel:970-416-2910">970-416-2910</a></>,
        header3: 'Fort Lupton Housing Authority',
        body3: <>Fort Lupton Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:303-857-4400">303-857-4400</a></>,
        header4: 'Greeley Transitional House',
        body4: <>The Greeley Transitional House is the only homeless shelter in Greeley offering emergency and transitional shelter, case management and follow-up programs for homeless families. Phone: <a href="tel:970-352-3215">970-352-3215</a></>,
        header5: 'Holyoke Housing Authority',
        body5: <>Holyoke Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-854-2289">970-854-2289</a></>,
        header6: 'Homelessness Prevention Initiative of Larimer County',
        body6: <>HPILC provides rental assistance to members of the local community. Phone: <a href="tel:970-221-1553">970-221-1553</a></>,
        number: 39
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Immanuel Lutheran Church',
        body: <>Immanuel Lutheran Church has small monetary donations and support for paying rent or utilities. Aid is a last resort and only is available as funds allow on a week-to-week basis. Phone: <a href="tel:970-352-3003">970-352-3003</a></>,
        header2: 'Larimer County Office on Aging',
        body2: <>Larimer County Office on Aging provides seniors and/or residents over the age of 60 the ability to apply for public and government assistance from programs. Phone: <a href="tel:970-498-7760">970-498-7760</a></>,
        header3: 'LaSalle Ministry Alliance',
        body3: <>The LaSalle Ministry Alliance program provides help to qualifying low-to-moderate income applicants in LaSalle for utilities, rent and other needs. Phone: <a href="tel:970-388-2445">970-388-2445</a></>,
        header4: 'Loveland Housing Authority',
        body4: <>Provides financial assistance to those who qualify. Phone: <a href="tel:970-635-5935">970-635-5935</a></>,
        header5: 'Neighbor to Neighbor',
        body5: <>Neighbor to Neighbor is a nonprofit agency offers housing assistance to those who qualify. Phone: <a href="tel:970-663-4163">970-663-4163</a></>,
        header6: 'Salvation Army Fort Collins',
        body6: <>The Salvation Army agency has limited funds for paying rent, utility and heating bills, and general basic needs. Phone: <a href="tel:720-207-4472">720-207-4472</a> (Larimer County)</>,
        number: 40
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Steppin’ Out Inc.',
        body: <>Steppin’ Out Inc. provides individuals 21 or younger with emergency assistance and funds to help pay for rent, auto repairs or insurance, clothing, free food, cell phone, utilities, health emergencies or education expenses. Phone: <a href="tel:970-484-1463">970-484-1463</a></>,
        header2: 'The Our Center Basic Needs Programs',
        body2: <>The Our Center Basic Needs Programs assists low-income individuals and families who are currently residing in Dacono, Longmont, Frederick, Firestone and the Mead Colorado geographic area. Get access to free food, clothing, rent assistance, shelter, local transportation, emergency medical, utilities and heating help, budget counseling and financial education. Phone: <a href="tel:303-772-5529">303-772-5529</a></>,
        header3: 'Weld County Catholic Charities',
        body3: <>The Weld County Catholic Charities offers several different services and assistance programs including financial and emergency assistance and the Guadalupe Shelter. Phone: <a href="tel:970-353-6433">970-353-6433</a></>,
        header4: 'Boulder County AIDS Project',
        body4: <>The Boulder County AIDS Project provides grants for clients with symptomatic infection who have urgent needs that their regular income cannot cover. Expenses such as rent, insurance premiums and medical bills may be covered. Phone: <a href="tel:303-444-6121">303-444-6121</a></>,
        number: 41
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Boulder Housing Partners',
        body: <>Boulder Housing Partners build, own and manage affordable housing for low-and-moderate income residents and are committed to fostering a healthy sense of community in every property they develop. Phone: <a href="tel:720-564-4610">720-564-4610</a></>,
        header2: 'Boulder Shelter for the Homeless',
        body2: <>The Boulder Shelter for the Homeless provides safe shelter, food, support services and an avenue to self-sufficiency for homeless adults in the community. Phone: <a href="tel:303-442-4646">303-442-4646</a></>,
        header3: 'Carriage House Community Table',
        body3: <>The Carriage House Community Table offers financial assistance for paying rent, prescription medications and eyeglasses. A Director’s Discretionary Fund is available to be primarily used to pay for hotel vouchers when people are sick or for medication. Phone: <a href="tel:303-442-8300">303-442-8300</a></>,
        header4: 'Emergency Family Assistance Association (EFAA)',
        body4: <>EFAA helps those in the community whose immediate needs for food, shelter and other basic necessities cannot be adequately met by other means, and supports their efforts toward financial stability or self-sufficiency. Phone: <a href="tel:303-442-3042">303-442-3042</a></>,
        header5: 'Carbondale Senior Housing Corporation',
        body5: <>Carbondale Senior Housing is a subsidized housing project for low-income seniors. Phone: <a href="tel:970-963-9326">970-963-9326</a></>,
        number: 42
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Catholic Charities of Western Slope',
        body: <>The Catholic Charities of Western Slope is a charity to assist families and individuals who are in need of housing support, such as rent assistance, heating and utility bill assistance, dental care and aid, transportation, shelter, lodging and more. Phone: <a href="tel:970-384-2060">970-384-2060</a></>,
        header2: 'Durango Housing Corporation',
        body2: <>Durango Housing Corporation is committed to providing affordable, quality housing and provides a computerized learning center for residents and non-residents alike. Phone: <a href="tel:970-247-2788">970-247-2788</a></>,
        header3: 'Garfield County Housing Authority',
        body3: <>The Garfield County Housing Authority offers rental and housing assistance.</>,
        header4: 'Grand Junction Housing Authority',
        body4: <>Grand Junction Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-245-0388">970-245-0388</a></>,
        header5: 'Grand Valley Catholic Outreach',
        body5: <>The Grand Valley Catholic Outreach provides money for rent and housing expenses to those in need.</>,
        header6: 'Gunnison County Housing Authority',
        body6: <>Gunnison County Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-641-7901">970-641-7901</a></>,
        number: 43
    },
    {
        title: 'Housing Financial Assistance Program',
        header: 'Gunnison County Social Services',
        body: <>The Gunnison County Social Services provides struggling families and individuals with access to government programs, aid and various forms of support. Phone: <a href="tel:970-641-7940">970-641-7940</a></>,
        header2: 'Housing Authority of Montezuma',
        body2: <>Housing Authority of Montezuma provides financial assistance to those who qualify. Phone: <a href="tel:970-565-3831">970-565-3831</a></>,
        header3: 'Housing Solutions for the Southwest (HS)',
        body3: <>HS provides resources and programs for those who are struggling with their bills or debts. They include the Eviction and Emergency Homeless Prevention Program, which provides rent assistance, cash grants and other emergency aid. Funds are available for paying a mortgage, rent and energy bills. Phone: <a href="tel:970-259-1086">970-259-1086</a></>,
        header4: 'Meeker Housing Authority',
        body4: <>Meeker Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-878-5536">970-878-5536</a></>,
        header5: 'Moffat Housing Authority',
        body5: <>Moffat Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-824-3660">970-824-3660</a></>,
        header6: 'Montrose County Housing Authority',
        body6: <>The Montrose County Housing Authority is a nonprofit government agency that deals with housing, rent and landlord issues. Phone: <a href="tel:970-323-5445">970-323-5445</a></>,
        header7: 'Rifle Housing Authority',
        body7: <>Rifle Housing Authority provides financial assistance to those who qualify. Phone: <a href="tel:970-945-3072">970-945-3072</a></>,
        number: 44
    },
    {
        TOC: 'Legal Services (p. 46)',
        title: 'Legal Services',
        header: 'Disability Law Colorado',
        body: 'Protects and promotes the rights of people with disabilities and older people in Colorado through direct legal representation, advocacy, education and legislative analysis.',
        link: 'https://disabilitylawco.org',
        pdf: legal,
        number: 45
    },
    {
        TOC: "Nursing Mothers Resources (p. 47)",
        title: 'Nursing Mothers Resources',
        header: "Breastfeeding Management Clinic at Children's Hospital",
        body: 'Provides a comprehensive approach to breastfeeding challenges for the baby and family',
        link: 'https://www.childrenscolorado.org/doctors-and-departments/departments/primary-care/breastfeeding-management-clinic/',
        header2: 'Mother\'s Milk Bank',
        body2: <>Improving the quality of life for families, sick children and infants throughout our communities by providing support when and how they need it most Phone: <a href="tel:303-869-1888">303-869-1888</a></>,
        link2: 'https://rmchildren.org/mothers-milk-bank/',
        header3: 'Rose Lactation Support',
        body3: <>Phone: <a href="tel:303-320-2001">303-320-2001</a></>,
        header4: 'PSL Lactation Support Telephone Support',
        body4: <>Phone: <a href="tel:303-869-2212">303-869-2212</a></>,
        header5: 'International Board-Certified Lactation Consultants',
        body5: 'he IBCLC Care Directory lists recognized hospitals, birthing facilities, birthing services, and community-based health agencies that hire currently certified International Board-Certified Lactation Consultants (IBCLC) and have a dedicated lactation support program',
        link5: 'http://www.ibclccare.org/directory.html',
        pdf: nursingResources,
        number: 46
    },
    {
        TOC: "Recreational Resources (pp. 48-65)",
        title: 'Recreational Resources',
        specialHeader: 'Camps',
        header: "Adam’s Camp",
        body: 'To realize the potentials and develop the strengths of children and young adults by bringing together families with professionals and volunteers to collaboratively provide customized, intensive therapy, family support and recreation in a camp environment',
        link: 'http://adamscamp.org',
        header2: 'Aspen Camp',
        body2: 'A year around camp for the deaf and hard of hearing',
        link2: 'https://aspencamp.org',
        header3: 'Beyond Classroom Walls',
        body3: 'We help kids with disabilities gain higher independence by providing real-life activities taught through hands-on coaching',
        link3: 'http://beyondclassroomwalls.com/',
        header4: 'The Brain Injury Alliance of Colorado',
        body4: 'Through guidance, resources, support, and education, we seek to engage with Coloradans in the lifelong growth of those affected by an injury to the brain',
        link4: 'https://biacolorado.org',
        header5: 'Breckenridge Outdoor Education Center',
        body5: 'BOEC offers camps and retreats for individuals and custom group programs to anyone with any type of disability throughout the calendar year',
        link5: 'https://www.boec.org',
        pdf: recreation,
        number: 47
    },
    {
        title: 'Recreational Resources',
        header: "Camp Courage",
        body: 'Dedicated to providing a no cost outdoor camp experience to children diagnosed with cancer.',
        link: 'http://camp-courage.org',
        header2: 'Camp Hope at Buckhorn United Methodist Camp',
        body2: 'We aim to help every person who enters our camp explore the beautiful outdoors, grow in their faith, build lasting friendships, and create memories to carry with them for a lifetime',
        link2: 'http://www.buckhorncamp.org/camp-',
        header3: 'Camp Little Tree at the Children’s Hospital',
        body3: 'Children\'s Hospital Colorado offers summer camp programs designed to meet the needs of children who may find a mainstream or "regular" camp program difficult, or who need special care while away from home',
        link3: 'https://www.childrenscolorado.org/doctors-and-departments/departments/orthopedics/programs/rehab--physical-therapy/rehabilitation-summer-camps/',
        header4: 'Camp Paha',
        body4: 'Offering safe, challenging and fun leisure, recreational and educational opportunities for youth ages 6-17, Camp Paha provides an array of activities including swimming, sports, games, nature, music, drama, hiking, arts and crafts and field trips within the community',
        link4: 'http://www.lakewood.org/paha/',
        number: 48
    },
    {
        title: 'Recreational Resources',
        header: "CamPossible at the Children’s Hospital",
        body: 'Children\'s Hospital Colorado offers summer camp programs designed to meet the needs of children who may find a mainstream or "regular" camp program difficult, or who need special care while away from home.',
        link: 'https://www.childrenscolorado.org/doctors-and-departments/departments/orthopedics/programs/rehab--physical-therapy/rehabilitation-summer-camps/',
        header2: 'Camp Wapiyapi',
        body2: 'A summer camp for children facing pediatric cancer and their siblings. Offered at no cost to their families',
        link2: 'http://www.campwapiyapi.org',
        header3: 'Camp Zenith at the Children’s Hospital',
        body3: 'Children\'s Hospital Colorado offers summer camp programs designed to meet the needs of children who may find a mainstream or "regular" camp program difficult, or who need special care while away from home.',
        link3: 'https://www.childrenscolorado.org/doctors-and-departments/departments/orthopedics/programs/rehab--physical-therapy/rehabilitation-summer-camps/',
        header4: 'Colorado Acts-Shining Stars',
        body4: 'The school’s mission is to bring quality children’s drama education to the local community.',
        link4: 'http://www.coloradoacts.org',
        number: 49
    },
    {
        title: 'Recreational Resources',
        header: "Colorado Discover Ability Camp Freedom",
        body: 'To increase the independence, self-confidence, self worth and education of people with disabilities, their family and friends, through outdoor recreation',
        link: 'https://www.coloradodiscoverability.org/',
        header2: 'Colorado Foundation Conductive Education',
        body2: 'Is an educational program created to teach movement and problem-solving skills',
        link2: 'https://www.conductiveed.com',
        header3: 'Colorado Lions Camp',
        body3: 'To provide exceptional camping programs to individuals with varying abilities which promote independence, challenge their abilities and provide an opportunity to discover his or her own potential in a safe, positive environment.',
        link3: 'http://www.coloradolionscamp.org',
        header4: 'Confidence Camp- Colorado School of the Blind',
        body4: 'Provides innovative teaching techniques, daily challenges and self-confidence that are the building blocks of independence, opportunity and success',
        link4: 'http://cocenter.org/our-programs/summer-youth/',
        header5: 'Durango Mountain Camp',
        body5: 'The Durango Mountain Camp (DMC) provides an intensive 5 1⁄2 week language immersion program for diagnosed dyslexic students’ ages 11 – 16 (day campers 7 – 13)',
        link5: 'http://durangomountaincamp.com',
        number: 50
    },
    {
        title: 'Recreational Resources',
        header: "Easter Seals",
        body: 'Provides programs and supports that enhance quality of life and create opportunities for greater independence at home, at work and at play.',
        link: 'http://www.easterseals.com/co/our-programs/',
        header2: 'Expand Boulder County Program',
        body2: 'Creating a community of ALL ABILITIES through recreation',
        link2: 'https://bouldercolorado.gov/parks-rec/expand-program-for-people-with-disabilities',
        header3: 'Firefly Autism',
        body3: 'To transform the lives of children with autism. Partnering with families, we create life-long relationships through thoughtful, innovative, empirical learning treatment programs.',
        link3: 'https://fireflyautism.org/',
        header4: 'Garden Autism Services',
        body4: 'To provide exceptional & comprehensive programming for young people with autism, their families & communities',
        link4: 'http://www.gardencolorado.org',
        header5: 'Kids Street',
        body5: 'Is a family-centered, multidisciplinary, community-based program designed to maximize the health and development of young children who are dependent on medical technology.',
        link5: 'https://www.childrenscolorado.org/your-visit/locations/kidstreet/',
        number: 51
    },
    {
        title: 'Recreational Resources',
        header: "Sky High Hope Camp",
        body: 'Our mission is to provide a one-week, genuine, summer camp experience for children and teens with current or past pediatric cancer and/or limb loss/limb-threatening conditions due to tumor, trauma, or infection',
        link: 'http://skyhighhope.org',
        header2: 'Spectra Autism Center',
        body2: 'To bring forward the unique talents, abilities and personalities of individuals with neurodevelopmental disorders and other psychological needs so they can lead meaningful and rewarding livesCreating a community of ALL ABILITIES through recreation',
        link2: 'http://www.spectracenter.org',
        header3: 'Roundup River Ranch',
        body3: 'Roundup River Ranch offers old-fashioned, pure fun camp experiences for children with serious illnesses and their families',
        link3: 'http://www.roundupriverranch.org/contact',
        header4: 'Tikvah Program at Ramah Outdoor Adventure',
        body4: 'To nurture the character development of Jewish youth by providing them the opportunity to challenge themselves physically, intellectually, and spiritually',
        link4: 'http://www.ramahoutdoors.org/about/tikvah/',
        header5: 'YMCA Camp Jackson',
        link5: 'http://www.campjackson.com',
        number: 52
    },
    {
        title: 'Recreational Resources',
        specialHeader: 'Recreational Organizations and Facilities',
        header: 'Achilles International Colorado',
        body: "Transforms the lives of people with disabilities through athletic programs and social connection.",
        link: 'https://www.achillesinternational.org/national-chapters/',
        header2: 'Aurora Recreation Therapeutic Programs',
        body2: 'Encouraging meaningful recreation opportunities and programs for individuals with disabilities to enhance and expand social, cognitive, affective and physical abilities.',
        link2: 'https://www.auroragov.org/things_to_do/recreation___sports_programs/adaptive_recreation___inclusion_services',
        header3: 'Autism Community Store',
        body3: 'Autism resources',
        link3: 'https://www.autismcommunitystore.com',
        header4: 'Boy Scouts of America- Special Needs Scouting',
        body4: 'Promotes a culture where each youth, volunteer, and employee feels a sense of belonging and builds communities where every person feels respected and valued',
        link4: 'http://www.denverboyscouts.org/openrosters/view_homepage.aspx?orgkey=1815',
        header5: 'Breckenridge Outdoor Education Center',
        body5: 'Expanding the potential of people with disabilities and special needs through meaningful educational, and inspiring outdoor experiences',
        link5: 'https://www.boec.org',
        number: 53
    },
    {
        title: 'Recreational Resources',
        header: 'Broomfield Therapeutic Recreation',
        body: "Designed to provide and enhance recreation opportunities for people of all ability levels and ages to participate in leisure services",
        link: 'http://www.broomfield.org/342/Therapeutic-Recreation',
        header2: 'City of Lakewood Parks and Recreation (R.I.S.E)',
        body2: 'Committed to providing recreation choices through special and inclusive recreation opportunities to individuals of all abilities, age six through senior adult',
        link2: 'http://www.lakewood.org/Community_Resources/Recreation/Patrons_with_Disabilities/Therapeutic_Recreation.aspx',
        header3: 'Colorado Come to Life',
        body3: 'Resources and information for accessible travel in Colorado',
        link3: 'http://www.colorado.com/articles/accessible-colorado-travel',
        header4: 'Denver Parks and Recreation',
        body4: 'Strives to provide everyone with equitable access to quality programs and amenities throughout the city',
        link4: 'https://www.denvergov.org/content/denvergov/en/denver-parks-and-recreation/activities-programs/adaptive-recreation.html',
        header5: 'Easter Seals',
        body5: 'Fostering an inclusive Colorado by ensuring all people with disabilities, older adults and caregivers have the programs and supports they need.',
        link5: 'http://www.easterseals.com/co/our-programs',
        number: 54
    },
    {
        title: 'Recreational Resources',
        header: 'Broomfield Therapeutic Recreation',
        body: "Everyone, regardless of ability, deserves choices for health, leisure, recreation, education, advocacy and wellness to enhance the quality of their lives",
        link: 'https://www.evergreenrecreation.com/232/INSPIRE-Special-Needs',
        header2: 'Expand-Boulder County Program',
        body2: 'Creating a community of ALL ABILITIES through recreation',
        link2: 'https://bouldercolorado.gov/parks-rec/expand-program-for-people-with-disabilities',
        header3: 'CAMP AMICO Fishing License and Accessible Locations',
        body3: 'Colorado Parks and Wildlife strives to make sure that everyone can access our special places and activities',
        link3: 'http://cpw.state.co.us/aboutus/Pages/Accessibility.aspx',
        header4: 'Highlands Ranch Community Association',
        body4: 'HRCA Therapeutic Recreation Programs are intended to enhance the quality of life of individuals with special needs.',
        link4: 'https://hrcaonline.org/classes-camps-activities/therapeutic-recreation/edgpid/4814/edgmid/7614',
        number: 55
    },
    {
        title: 'Recreational Resources',
        header: 'Magic Moments',
        body: "Promotes the arts by fostering an environment of acceptance, growth, learning, and support for our participants and the larger community by inviting people of all ages, with or without disabilities, amateur or professional, to perform in original musical theater productions.",
        link: 'https://magicmomentsinc.org',
        header2: 'Mountain Tiger Society',
        body2: 'Provide recreation and community safety skills for adults and teens with developmental disabilities.',
        link2: 'http://mountaintiger.org',
        header3: 'Parker Recreation Center',
        body3: 'Designed to enhance the quality of life for individuals with disabilities through participation in adapted programming',
        link3: 'http://www.parkerrec.com/892/Therapeutic-Recreation',
        header4: 'Phamaly Theater Company',
        body4: 'A creative home for theatre artists with disabilities; to model a disability-affirmative theatrical process; and to upend conventional narratives by transforming individuals, audiences, and the world',
        link4: 'http://www.phamaly.org',
        header5: 'The Rhythm Within',
        link5: 'http://www.therhythmwithin.net',
        number: 56
    },
    {
        title: 'Recreational Resources',
        header: 'Sensory Friendly Films at AMC Theatres',
        body: "Offering unique movie showings where we turn the lights up, and turn the sound down, so you can get up, dance, walk, shout or sing",
        link: 'https://www.amctheatres.com/programs/sensory-friendly-films',
        header2: 'State Parks Pass',
        body2: 'Colorado Parks and Wildlife strives to make sure that everyone can access our special places and activities.',
        link2: 'http://cpw.state.co.us/aboutus/Pages/Accessibility.aspx',
        header3: 'VSA Colorado',
        body3: 'Engages the community by opening doors to creative, educational and economic opportunities for people with disabilities to access, experience and benefit from the arts',
        link3: 'http://accessgallery.org',
        header4: 'Wellspring',
        body4: 'Provide educational, enrichment and business enterprise opportunities to adults with special needs such that they are empowered to live full, productive and satisfying lives in a God-centered and spiritually nourishing environment',
        link4: 'https://www.wearewellspring.org',
        header5: 'Wilderness on Wheels',
        body5: 'Providing ACCESS TO NATURE for people of all abilities, their families, friends and caregivers for over 30 years',
        link5: 'http://www.wildernessonwheels.org/contact/',
        number: 57
    },
    {
        title: 'Recreational Resources',
        specialHeader: 'Adaptive Sports',
        header: 'Adaptive Adventures',
        body: "To provide progressive outdoor sports opportunities to improve quality of life for children, adults and veterans with physical disabilities and their families.",
        link: 'https://adaptiveadventures.org',
        header2: 'Adaptive Recreation for Childhood Health (ARCH)',
        body2: 'Adaptive Recreation for Childhood Health (ARCH) at Children\'s Hospital Colorado, formerly the Hospital Sports Program, or HSP, helps kids with physical disabilities find confidence and freedom through sports and outdoor recreation',
        link2: 'Website:https://www.childrenscolorado.org/doctors-and-departments/departments/orthopedics/programs/adaptive-recreation-for-childhood-health/',
        header3: 'Adaptive Sports Center',
        body3: 'The Adaptive Sports Center enhances the quality of life of people with disabilities through exceptional outdoor adventure activities',
        link3: 'http://www.adaptivesports.org',
        header4: 'APS Aquatic Programs',
        body4: 'The programs goals are to, teach swim lessons to the community, emphasize the importance of safety in and out of the water, offer upper level swim teams to the community',
        link4: 'http://athletics.aurorak12.org/aquatics/',
        number: 58
    },
    {
        title: 'Recreational Resources',
        header: 'Breckenridge Outdoor Education Center',
        body: "Provides life changing outdoor learning experiences to people of all abilities, providing a sense of freedom to those who are routinely excluded from outdoor activity due to a disability or special need",
        link: 'https://www.boec.org',
        header2: 'Colorado Avalanche Sled Hockey',
        body2: 'The Colorado Adaptive Sports Foundation (CASF) aims to bolster independence, improve self-confidence, elevate social interaction and enhance quality of life through organized team sports. CASF is for people of all ages throughout Colorado who have physical disabilities including spinal cord injuries, spina-bifida, amputations and cerebral palsy.',
        link2: 'http://coloradoadaptivesports.org/programs-2-adaptive-team-sports-denver-co/colorado-avalanche-sled-hockey/',
        header3: 'Colorado Horse Power',
        body3: 'Committed to serving students with special needs through the therapeutic power of horses and horsemanship.',
        link3: 'https://www.coloradohorsepower.org',
        header4: 'Colorado Therapeutic Riding Center Inc.',
        body4: 'To change the lives of people with disabilities by promoting their physical, psychological and social well-being through equine assisted activities',
        link4: 'https://www.ctrcinc.org',
        number: 59
    },
    {
        title: 'Recreational Resources',
        header: 'Colorado Wheelchair Tennis Foundation',
        body: "Promote wheelchair tennis throughout the state of Colorado to players of all ages, sex, race and levels of play; act as a resource to further the development of the state's competitive players; and encourage the incorporation of wheelchair tennis into the mainstream.",
        link: 'http://www.cwtf.net',
        header2: 'Colorado Golden Eagles Ice Hockey Team',
        body2: 'Anyone age 5 or older with a developmental disability. If you do not know how to skate, we will teach you and we will outfit you with equipment. We will try and fit you with skates, but you may have to buy your own',
        link2: 'http://www.goldeneagleshockey.org/index.html',
        header3: 'Golf 4 The Disabled',
        body3: 'A gateway, bringing recreational golf therapy to those with disabilities to learn and enjoy the game of golf',
        link3: 'https://golf4thedisabled.org/',
        header4: 'Little League Baseball- The Challenger Division',
        body4: 'Any individual with a physical or intellectual challenge may participate.',
        link4: 'https://www.littleleague.org/play-little-league/challenger/',
        header5: 'National Sports Center for the Disabled',
        body5: 'Creating and providing adaptive outdoor recreation experiences',
        link5: 'http://nscd.org',
        number: 60
    },
    {
        title: 'Recreational Resources',
        header: 'Overstreet Dance',
        body: "Freedom Through Dance transforms the lives of those challenged by physical disabilities to find their own freedom of expression, and new levels of social interaction, through the art of ballroom dance",
        link: 'http://overstreetdance.com/wp/',
        header2: 'Praying Hands Ranches',
        body2: 'Committed to meeting the needs of mentally, emotionally and physically challenged children, adults, troubled youth and disabled veterans in a ranch environment therapeutic center',
        link2: 'http://www.prayinghandsranch.org',
        header3: 'Promise Ranch Therapeutic Riding',
        body3: 'Helps clients minimize disability and maximize ability through the provision of individualized, safe, high-quality, and effective equine-assisted and nature-based therapies and activities',
        link3: 'https://www.prtr.org',
        header4: 'The Right Step',
        body4: 'Providing therapeutic riding and equine assisted activities for children and adults with physical, cognitive, emotional, behavioral, and learning disabilities',
        link4: 'http://www.therightstepinc.org',
        header5: 'Safe Splash Swim School',
        link5: 'www.safesplash.com',
        number: 61
    },
    {
        title: 'Recreational Resources',
        header: 'STARS Steamboat Adaptive Recreational Sports',
        body: "Our mission is to empower and enrich lives through adaptive recreational activities",
        link: 'http://steamboatstars.com',
        header2: 'Special Olympics of Colorado',
        body2: 'Provide year-round sports training and athletic competition in a variety of Olympic-type sports for children and adults with intellectual disabilities',
        link2: 'http://www.specialolympicsco.org',
        header3: 'Spoke N Motion Dance Company',
        body3: 'The troupe celebrates a passion for music and movement in a diverse population of dancers with and without disabilities in a unique, creative art form called Integrated Dance',
        link3: 'http://www.spokenmotiondance.org/home.html',
        header4: 'Sports Made Possible',
        body4: 'Provides children and adults, who are living with various mental and/or physical challenges, an opportunity to play baseball as a team member in an organized league.',
        link4: 'http://www.sportsmadepossible.org',
        header5: 'TOP Soccer',
        body5: 'US Youth Soccer TOPSoccer (The Outreach Program for Soccer) is a community-based training and team placement program for young athletes with disabilities, organized by youth soccer association volunteers.',
        link5: 'www.usyouthsoccer.org/topsoccer/#:~:text=US%20Youth%20Soccer%20TOPSoccer%20(The,by%20youth%20soccer%20association%20volunteers.',
        number: 62
    },
    {
        title: 'Recreational Resources',
        header: 'United States Association of Blind Athletes',
        body: "The United States Association of Blind Athletes empowers Americans who are blind and visually impaired to experience life-changing opportunities in sports, recreation and physical activities.",
        link: 'http://usaba.org/index.php/membership/',
        specialHeader2: 'Adaptive Park',
        header2: 'Northglenn Sensory Park:',
        body2: 'Northglenn Sensory Park offers adventures for all abilities.',
        link2: 'https://www.dcla.net/northglenn-sensory-playground/',
        header3: 'Westminster Sensory Park:',
        link3: 'https://kaboom.org/resources/build_playground_toolkit/accessibility/featured_accessible_playspaces/westminster_sensory_park',
        header4: 'Red Tailed Hawk Park:',
        body4: 'Address: 23701 E. Hinsdale, Way Aurora, CO 80016',
        header5: 'Sensory Garden: The Denver Botanic Gardens:',
        link5: 'Website: https://www.botanicgardens.org/',
        header6: 'Adaptive Recreational Activities Throughout Denver',
        link6: 'https://apm.activecommunities.com/denver/Activity_Search?detailskeyword=adaptive&IsAdvanced=True&ddlSortBy=Activity+name&DaysOfWeek=0000000&SearchFor=2&SearchLevelID=2&maxAge=100&NumberOfItemsPerPage=20&IsSearch=true',
        header7: 'Recreational Activities throughout Denver',
        link7: 'Website: http://www.thrivectr.org/disability-recreation-resources',
        number: 63
    },
    {
        title: 'Recreational Resources',
        header: 'Hippotherapy Centers',
        body: "Multiple in the area – search the internet for Hippotherapy centers in your area",
        header2: 'Sensory Playgrounds',
        body2: 'Multiple in the area – search the internet for sensory playgrounds in your area',
        number: 64
    },
    {
        TOC: "Respite Care (p. 66)",
        title: 'Respite Care',
        header: "Colorado Respite Coalition",
        body: 'Promote awareness of and access to respite care services and resources that help improve the quality of life for caregivers and the individuals they care for',
        link: 'http://www.coloradorespitecoalition.org/index.php',
        header2: 'Megan’s Place',
        body2: 'Provide care for children with physical and developmental disabilities ages 3-18. We offer care after school, evenings, and weekends. We provide summer programs with a variety of activities and field trips.',
        link2: 'http://www.megansplacellc.org',
        header3: 'Specialized Sitters',
        body3: 'Offering in-home care for children with emotional, behavioral, and mental health needs',
        link3: 'http://specializedsitters.com/',
        header4: 'YMCA of Metropolitan Denver- Autism Respite Program',
        link4: 'https://www.denverymca.org/programs/child-care/out-school-time-care',
        pdf:respiteCare,
        number: 65
    },
    {
        TOC: "Transportation Services (p. 67)",
        title: 'Transportation Services',
        header: "Access-A-Ride",
        link: 'http://www.rtd-denver.com/accessARide.shtml',
        header2: 'Denver Regional Mobility and Access Council',
        body2: 'Promotes mobility and access for all by addressing transit needs through public outreach and by coordinating the efforts of government agencies and transit providers',
        link2: 'https://www.drmac-co.org',
        header3: 'Freedom Mobility',
        body3: 'A national chain of wheelchair accessible van providers – serving the disabled community with wheelchair accessible minivans, full-size vans with lifts and commercial fleet vehicles.',
        link3: 'https://www.mobilityworks.com/',
        header4: 'Handicapped Parking Permit',
        link4: 'https://www.colorado.gov/pacific/dmv/persons-disabilities',
        header5: 'RTD Discounted Fares',
        link5: 'Website: http://www.rtd-denver.com/DiscountFares.shtml',
        header6: 'Via Colorado',
        body6: 'A full-spectrum mobility manager offering paratransit, travel training and mobility options information and referral services. Via also provides a wide range of community and group educational resources related to transportation for older adults, people with disabilities and others living with mobility limitations.',
        link6: 'https://viacolorado.org/about/',
        pdf: transportation,
        number: 66
    },
]