import React, { forwardRef, useRef } from 'react'
import HTMLFlipBook from "react-pageflip"
import logo from "../../assets/images/logo/logo.png"
import { resources } from '../../const/resources'
import ResourceBookBubbles from '../BackgroundBubbles/resources/ResourceBookBubbles'
import pdf from '../../assets/images/portals/pdf.png'

interface mobileProps {
    isMobile: boolean,
}
 
const Page: any = forwardRef((props, ref: any) => {
    return (
      <div className="page" ref={ref}>
        {props.children}
      </div>
    )
})
   
const MyBook: React.FC<mobileProps> = (props) => {
    const pageFlipRef: any = useRef()
    const flipTo: any = (pageNum) => {
        pageFlipRef.current.pageFlip.flip(pageNum)
    }

return (
    <div className={`book-container ${props.isMobile ? "mobile" : ""}`}>
        <ResourceBookBubbles />
        <HTMLFlipBook className="book" width={300} height={400} size={"stretch"} minWidth={200} maxWidth={500} minHeight={300} maxHeight={400} ref={pageFlipRef}>
            <Page>
                <div className="table-of-contents">
                    <h2>Table Of Contents</h2>
                    {
                        resources.map((resource, idx) => 
                            <>
                            {
                                resource.TOC ?
                                <div key={idx} className="toc-flex">
                                    <div className="icon-image">
                                        <a href={resource.pdf} target="_blank"><img src={pdf} alt="Spark Home Health | PDF Link" /></a>
                                    </div>
                                    <p onClick={() => flipTo(resource.number)}>{resource.TOC}</p>
                                </div>
                                :
                                null
                            }
                            </>
                        )
                    }
                </div>
            </Page>
            <Page>
                <div className="image">
                    <img src={logo} alt="Spark Home Health | Resource Book | Logo" />
                </div>
            </Page>
            {
                resources.map((resource, idx) => 
                    <Page key={idx}>
                        <div className="page-set-up">
                            <h1>{resource.title}</h1>
                            <div className="page-content">
                                <div className="page-content__section">
                                    {
                                        resource.specialHeader ?
                                        <h3>{resource.specialHeader}</h3>
                                        :
                                        null
                                    }
                                    {
                                        resource.header ?
                                        <h2>{resource.header}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.body ?
                                        <p>{resource.body}</p>
                                        :
                                        null
                                    }
                                    {
                                        resource.link ?
                                        <p>Website:<a href={resource.link} target="_blank">{resource.link}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.specialHeader2 ?
                                        <h3>{resource.specialHeader2}</h3>
                                        :
                                        null
                                    }
                                    {
                                        resource.header2 ?
                                        <h2>{resource.header2}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.body2 ?
                                        <p>{resource.body2}</p>
                                        :
                                        null
                                    }
                                    {
                                        resource.link2 ?
                                        <p>Website:<a href={resource.link2} target="_blank">{resource.link2}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.specialHeader3 ?
                                        <h3>{resource.specialHeader3}</h3>
                                        :
                                        null
                                    }
                                    {
                                        resource.header3 ?
                                        <h2>{resource.header3}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.body3 ?
                                        <p>{resource.body3}</p>
                                        :
                                        null
                                    }
                                    {
                                        resource.link3 ?
                                        <p>Website:<a href={resource.link3} target="_blank">{resource.link3}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.specialHeader4 ?
                                        <h3>{resource.specialHeader4}</h3>
                                        :
                                        null
                                    }
                                    {
                                        resource.header4 ?
                                        <h2>{resource.header4}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.body4 ?
                                        <p>{resource.body4}</p>
                                        :
                                        null
                                    }
                                    {
                                        resource.link4 ?
                                        <p>Website:<a href={resource.link4} target="_blank">{resource.link4}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.header5 ?
                                        <h2>{resource.header5}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.body5 ?
                                        <p>{resource.body5}</p>
                                        :
                                        null
                                    }
                                    {
                                        resource.link5 ?
                                        <p>Website:<a href={resource.link5} target="_blank">{resource.link5}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.header6 ?
                                        <h2>{resource.header6}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.body6 ?
                                        <p>{resource.body6}</p>
                                        :
                                        null
                                    }
                                    {
                                        resource.link6 ?
                                        <p>Website:<a href={resource.link6} target="_blank">{resource.link6}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.header7 ?
                                        <h2>{resource.header7}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.body7 ?
                                        <p>{resource.body7}</p>
                                        :
                                        null

                                    }
                                    {
                                        resource.link7 ?
                                        <p>Website:<a href={resource.link7} target="_blank">{resource.link7}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.header8 ?
                                        <h2>{resource.header8}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.link8 ?
                                        <p>Website:<a href={resource.link8} target="_blank">{resource.link8}</a></p>
                                        :
                                        null
                                    }
                                </div>
                                <div className="page-content__section">
                                    {
                                        resource.header9 ?
                                        <h2>{resource.header9}</h2>
                                        :
                                        null
                                    }
                                    {
                                        resource.link9 ?
                                        <p>Website:<a href={resource.link9} target="_blank">{resource.link9}</a></p>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="number">
                            <p>{resource.number}</p>
                        </div>
                        {
                            resource.number % 2 === 0 ? 
                                <p className="back-book-btn" onClick={() => flipTo(0)}>Back to Table of Contents</p>
                                :
                                null
                        }
                    </Page>
                )
            }
            <Page>
                <div className="end-cover">
                    <div className="image">
                        <img src={logo} alt="Spark Home Health | Resource Book | End Logo" />
                    </div>
                    <p className="back-book-btn" onClick={() => flipTo(0)}>Back to Table of Contents</p>
                </div>
            </Page>
        </HTMLFlipBook>
    </div>
    )
}

export default MyBook