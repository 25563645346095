const RequestConfirmationBubbles: React.FC = () => {
    return (
        <div>
            <div className="thank-you-bubble1" />
            <div className="thank-you-bubble2" />
            <div className="thank-you-bubble3" />
            <div className="thank-you-bubble4" />
            <div className="thank-you-bubble5" />
            <div className="thank-you-bubble6" />
            <div className="thank-you-bubble7" />
            <div className="thank-you-bubble8" />
            <div className="thank-you-bubble9" />
            <div className="thank-you-bubble10" />
            <div className="thank-you-bubble11" />
            <div className="thank-you-bubble12" />
            <div className="thank-you-bubble13" />
            <div className="thank-you-bubble14" />
            <div className="thank-you-bubble15" />
            <div className="thank-you-bubble16" />
        </div>
    )
}

export default RequestConfirmationBubbles