import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom'
import { useAuth } from '../Firebase/Firebase'

//scss
import '../../scss/_main.scss'

// components
import Navigation from '../Navigation/Navigation'
import JoinOurTeam from '../JoinOurTeam/JoinOurTeam'
import TM from '../ServicePages/TM/TM'
import CovidResponse from '../CovidResponse/CovidResponse'
import Footer from '../Navigation/Footer'
import ContactUs from '../ContactUs/ContactUs'
import EmployeeLogin from '../Portals/Employee/EmployeeLogin/EmployeeLogin'
import PatientLogin from '../Portals/Patient/PatientLogin/PateintLogin'
import PatientPortal from '../Portals/Patient/PatientPortal/PatientPortal'
import AdminLogin from '../Portals/Admin/AdminLogin'
import RequestAService from '../RequestAService/RequestAService'
import RequestConfirmation from '../RequestAService/RequestConfirmation'
import Resources from '../Resources/Resources'
import PageNotFound from '../404/404'

const Home = lazy(() => import('../Home/Home'))
const AboutUs = lazy(() => import('../About/AboutUs/AboutUs'))
const AboutOurServices = lazy(() => import('../About/AboutOurServices/AboutOurServices'))
const ST = lazy(() => import('../ServicePages/ST/ST'))
const PT = lazy(() => import('../ServicePages/PT/PT'))
const OT = lazy(() => import('../ServicePages/OT/OT'))
const Nursing = lazy(() => import('../ServicePages/Nursing/Nursing'))
const NICU = lazy(() => import('../ServicePages/NICU/NICU'))
const Feeding = lazy(() => import('../ServicePages/Feeding/Feeding'))
const AdminPortal = lazy(() => import('../Portals/Admin/AdminPortal'))
const EmployeePortal = lazy(() => import('../Portals/Employee/EmployeePortal/EmployeePortal'))

const App: React.FC = () => {
  const auth = useAuth()
  
  return (
    <div className="App">
      {/* <CovidBar /> */}
      <Navigation />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about-us" exact component={AboutUs} />
        <Route path="/about-our-services" exact component={AboutOurServices} />
        <Route path="/join-our-team" exact component={JoinOurTeam} />
        <Route path="/speech-therapy" exact component={ST} />
        <Route path="/physical-therapy" exact component={PT} />
        <Route path="/occupational-therapy" exact component={OT} />
        <Route path="/acute-nursing" exact component={Nursing} />
        <Route path="/nicu" exact component={NICU} />
        <Route path="/feeding" exact component={Feeding} />
        <Route path="/telemedicine" exact component={TM} />
        <Route path="/covid-response" exact component={CovidResponse} />
        <Route path="/contact-us" exact component={ContactUs} />
        <Route path="/employee-login" exact component={EmployeeLogin} />
        <Route path="/patient-login" exact component={PatientLogin} />
        <Route path="/admin-login" exact component={AdminLogin} />
        <Route path="/request-a-service" exact component={RequestAService} />
        <Route path="/thank-you" exact component={RequestConfirmation} />
        <Route path="/resources" exact component={Resources} />
        {
          auth.user ?
          (
            auth.user ?
            <Route path="/patient-portal" exact component={PatientPortal} />
            :
            null
          )
          : 
          (
            null
          )
        }
        {
          auth.user ?
          (
            auth.user.email !== 'patient@sparkhomehealth.com' ?
            <Route path="/employee-portal" exact component={EmployeePortal} />
            :
            null
          )
          :
          (
            null
          )
        }
        {
          auth.user ?
          (
            auth.user.email === 'admin@sparkhomehealth.com' ?
            <Route path="/admin-portal" exact>
              <AdminPortal />
            </Route>
            :
            null
          )
          :
          (
            null
          )
        }
        <Route component={PageNotFound} />
      </Switch>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
}

export default App;
