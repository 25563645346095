import welcomeLetter from '../assets/images/portals/heart-mail.png'
import admissionDocuments from '../assets/images/portals/checklist.png'
import ptPal from '../assets/images/portals/pt-pal.png'
import emergency from '../assets/images/portals/first-aid-kit.png'
import newsletter from '../assets/images/portals/news.png'
import staffBios from '../assets/images/portals/window-group.png'
import resourceGuide from '../assets/images/portals/orange-book.png'
import summit from '../assets/images/portals/summit.png'
import sparkMail from '../assets/images/portals/rocket-mail.png'
import training from '../assets/images/portals/present-group.png'
import hr from '../assets/images/portals/exclaimation.png'
import therapy from '../assets/images/portals/purple-group.png'
import alora from '../assets/images/portals/alora.png'
import emergencyPreparednessForm from '../assets/Documents/portals/patient/emergency-preparedness-form.pdf'
import patientWelcomeLetter from '../assets/Documents/portals/patient/patient-welcome-letter.pdf'
import englishAdmissionForms from '../assets/Documents/portals/patient/english-admission-forms-fillable.pdf'
import spanishAdmissionForms from '../assets/Documents/portals/patient/spanish-admission-forms-fillable.pdf'
import otBio from '../assets/Documents/portals/patient/ot-bios.pdf'
import ptBio from '../assets/Documents/portals/patient/pt-bios.pdf'
import slBio from '../assets/Documents/portals/patient/slp-bios.pdf'


export const patientPortalData = [
    {
        img: welcomeLetter,
        text: 'Patient Welcome Letter',
        link: patientWelcomeLetter
    },
    {
        img: admissionDocuments,
        text: 'Admission Documents',
        englishForm: englishAdmissionForms,
        spanishForm: spanishAdmissionForms,
        toggle: 'admission'
    },
    {
        img: ptPal,
        text: 'Pt Pal Access',
        link: 'https://www.healthtechpal.com/login-patient.php?patient=1'
    },
    {
        img: emergency,
        text: 'Emergencey Preparedness Form',
        link: emergencyPreparednessForm
    },
    {
        img: newsletter,
        text: 'Newsletters',
        link: '/'
    },
    {
        img: staffBios,
        text: 'Staff Bios',
        bio1: otBio,
        bio2: ptBio,
        bio3: slBio,
        bio: 'bios'
    },
    {
        img: resourceGuide,
        text: 'Patient Resource Guide',
        link: '/resources'
    },
]

export const employeePortalData = [
    {
        img: alora,
        link: 'https://aloraplus.com/',
        class: 'alora',
        text: 'Alora'
    },
    {
        img: summit,
        link: 'https://summit-education.com/login',
        class: 'summit',
        text: 'Summit'
    },
    {
        img: sparkMail,
        text: 'Spark Mail',
        link: 'https://gmail.com'
    },
    // {
    //     img: training,
    //     text: 'Upcoming Training Opportunities'
    // },
    {
        img: hr,
        text: 'Human Resources Portal',
        link: 'https://www.empforce.com/Main/EmployeeLogin.aspx?l=0'
    },
    {
        img: therapy,
        text: 'Therapy/Teaming Resources',
        link: 'https://drive.google.com/drive/folders/1BFQb9JzgylNNGtJAfCf85-HFYBdkJ8wv?usp=sharing'
    },
    {
        img: ptPal,
        text: 'Pt Pal Access',
        link: 'https://www.healthtechpal.com/login.php'
    },
    {
        img: staffBios,
        text: 'Staff Bios',
        bio1: otBio,
        bio2: ptBio,
        bio3: slBio
    },
]