const RequestServiceBubbles: React.FC = () => {
    return (
        <div>
            <div className="request-service-bubble1" />
            <div className="request-service-bubble2" />
            <div className="request-service-bubble3" />
            <div className="request-service-bubble4" />
            <div className="request-service-bubble5" />
            <div className="request-service-bubble6" />
            <div className="request-service-bubble7" />
            <div className="request-service-bubble8" />
            <div className="request-service-bubble9" />
            <div className="request-service-bubble10" />
            <div className="request-service-bubble11" />
            <div className="request-service-bubble12" />
            <div className="request-service-bubble13" />
            <div className="request-service-bubble14" />
            <div className="request-service-bubble15" />
            <div className="request-service-bubble16" />
        </div>
    )
}

export default RequestServiceBubbles