import { useAuth } from '../../../Firebase/Firebase'
import { useHistory, Link } from 'react-router-dom'
import { patientPortalData } from '../../../../const/portals'
import { useState } from 'react'

const PatientPotal: React.FC = () => {
    const history = useHistory()
    const auth = useAuth()
    const [ isAdmission, setIsAdmission ] = useState(false)
    const [ isBio, setIsBio ] = useState(false)

    const handleSignOut = () => {
        auth.signout()
        history.push('/')
    }

    return (
        <div className="patient-portal">
            <button onClick={handleSignOut}>Sign Out</button>
            <div className="quote-bubble">
                <p>"I love how amazing and passionate our therapists are and how much we care about the families we serve!"<br /> -Spark Team Member</p>
                <div className="arrow" />
            </div>
            <div className="patient-portal__box">
                <h1>Patient Resources</h1>
                <div className="grid">
                {
                    patientPortalData.map(links => 
                        links.link ?
                        <a key={links.text} href={links.link} target="_blank">
                            <div className="links">
                                <img src={links.img} alt={links.text} />
                                <p>{links.text}</p>
                            </div>
                        </a>
                        :
                        links.toggle ?
                        <div key={links.text} className="other-link" onClick={() => setIsAdmission(true)}>
                            <div className="links">
                                <img src={links.img} alt={links.text} />
                                <p>{links.text}</p>
                            </div>
                        </div>
                        :
                        links.bio ?
                        <div key={links.text} className="other-link" onClick={() => setIsBio(true)}>
                            <div className="links">
                                <img src={links.img} alt={links.text} />
                                <p>{links.text}</p>
                            </div>
                        </div>
                        :
                        null
                    )
                }
                </div>
            </div>
            <div className={`admission-links ${isAdmission ? "show" : ""}`}>
                <div className="box">
                    <div className="close" onClick={() => setIsAdmission(false)}>
                        <div className="close__bar" />
                        <div className="close__bar" />
                    </div>
                    <h1>Admission Forms</h1>
                    {
                        patientPortalData.map(links =>
                            {
                                return (
                                    links.toggle === "admission" ?
                                    <ul key={links.text}>
                                        <li><a href={links.englishForm} target="_blank">English Admission Form</a></li>
                                        <li><a href={links.spanishForm} target="_blank">Spanish Admission Form</a></li>
                                    </ul>
                                    :
                                    null
                                )
                            }
                        )
                    }
                </div>
            </div>
            <div className={`admission-links ${isBio ? "show": ""}`}>
                <div className="box">
                    <div className="close" onClick={() => setIsBio(false)}>
                        <div className="close__bar" />
                        <div className="close__bar" />
                    </div>
                    <h1>Staff Bios</h1>
                    {
                        patientPortalData.map(links =>
                            {
                                return (
                                    links.bio === 'bios' ?
                                    <ul key={links.text}>
                                        <li><a href={links.bio1} target="_blank">Occupational Therapy</a></li>
                                        <li><a href={links.bio2} target="_blank">Physical Therapy</a></li>
                                        <li><a href={links.bio3} target="_blank">Speech Language Pathology</a></li>
                                    </ul>
                                    :
                                    null
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default PatientPotal