const TMBubbles: React.FC = () => {
    return (
        <div>
            <div className="tm-bubble1" />
            <div className="tm-bubble2" />
            <div className="tm-bubble3" />
            <div className="tm-bubble4" />
            <div className="tm-bubble5" />
            <div className="tm-bubble6" />
            <div className="tm-bubble7" />
            <div className="tm-bubble8" />
            <div className="tm-bubble9" />
            <div className="tm-bubble10" />
            <div className="tm-bubble11" />
            <div className="tm-bubble12" />
            <div className="tm-bubble13" />
            <div className="tm-bubble14" />
            <div className="tm-bubble15" />
            <div className="tm-bubble16" />
            <div className="tm-bubble17" />
            <div className="tm-bubble18" />
            <div className="tm-bubble19" />
            <div className="tm-bubble20" />
            <div className="tm-bubble21" />
            <div className="tm-bubble22" />
            <div className="tm-bubble23" />
        </div>
    )
}

export default TMBubbles