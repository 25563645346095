import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import { BrowserRouter as Router } from 'react-router-dom'
import { ProvideAuth } from './components/Firebase/Firebase'
import logo from './assets/images/logo/logo.png'


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={
      <div className="fallback">
        <img src={logo} alt="Spark Home Health | Logo" />
      </div>
    }>
      <Router>
        <ScrollToTop>
          <ProvideAuth>
            <App />
          </ProvideAuth>
        </ScrollToTop>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
