import ContactUsBubbles from '../BackgroundBubbles/ContactUsBubbles'
import contactUs from '../../assets/images/contact-us/contact-us.png'
import { Link } from 'react-router-dom'

const ContactUs: React.FC = () => {
    return (
        <div className="contact-us">
            <ContactUsBubbles />
            <div className="contact-us__landing">
                <h1>We're Listening. Let us be a Spark in your day!</h1>
                <div className="cta">
                    <div className="image">
                        <img src={contactUs} alt="Spark Home Health | Girl with yellow shirt" />
                    </div>
                </div>
            </div>
            <div className="contact-us__box">
                <h2 className="header">Contact Us</h2>
                <p>
                    <a href="https://goo.gl/maps/EhCmL5CMCHbQiaap7" target="_blank">
                        1325 South Colorado Boulevard,<br />
                        Suite #B 312, Denver, CO 80222<br />
                    </a>
                    Office: <a href="tel:720-479-8952" target="_blank">720-479-8952</a><br />
                    Fax: <a href="tel:888-981-8064" target="_blank">888-981-8064</a><br />
                    <a href="mailto:admin@sparkhomehealth.com" target="_blank">admin@sparkhomehealth.com</a><br />
                </p>
            </div>
            <h2>Ready to request services? Get started below.</h2>
            <button><Link to="/request-a-service">Request Services</Link></button>
        </div>
    )
}

export default ContactUs