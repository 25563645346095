import ResourceBook from '../Resources/ResourceBook'
import { useState, useEffect } from 'react'
import ResourcesMobile from '../Resources/ResourcesMobile'

const Resources: React.FC = () => {
    const [ isMobile, setIsMobile ] = useState<boolean>(false)

    useEffect(() => {
        if (window.innerWidth <= 1000) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth <= 1000) {
                setIsMobile(true)
            } else {
                setIsMobile(false)
            }
        })
    }, [])

    return (
        <div className="resources">
            {
                !isMobile ?
                    <ResourceBook isMobile={isMobile} />
                    :
                    <ResourcesMobile isMobile={isMobile} />
            }
        </div>
    )
}

export default Resources