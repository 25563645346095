import { Link } from 'react-router-dom'
import CovidResponseBubbles from '../BackgroundBubbles/CovidResponseBubbles'

const CovidBar: React.FC = () => {
    return (
        <div className="covid-bar">
            <Link to="/covid-response"><p>Please find our COVID-19 policies here</p></Link>
        </div>
    )
}

export default CovidBar