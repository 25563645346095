import { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo/logo.png'
import CovidBar from '../CovidResponse/CovidBar'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../Firebase/Firebase'

const Navigation: React.FC = () => {
    const [ burgerClicked, setBurgerClicked ] = useState(false)
    const [ scrolled, setScrolled ] = useState(false)
    const [ showDropdown, setShowDropdown ] = useState<boolean>(false)

    const auth = useAuth()

    const handleScroll = () => {
        window.scrollY > 50 ? setScrolled(true) : setScrolled(false)
    }

    window.addEventListener('scroll', handleScroll)

    const handleBurger = () => {
        setBurgerClicked(!burgerClicked)
        setShowDropdown(false)
    }

    return (
        <div>
            <CovidBar />
            <div className={`nav ${scrolled ? "scrolled" : ""}`}>
                <div className="nav__logo">
                    <Link to="/"><img src={logo} alt="Spark Home Health Logo" /></Link>
                </div>
                <div className="nav__list">
                    <ul>
                        <li><NavLink to="/about-us" activeClassName="selected">About Us</NavLink></li>
                        <li className="dropdown-link"><NavLink to="/about-our-services" activeClassName="selected">About Our Services</NavLink>
                            <div className="dropdown-link__dropdown">
                                <ul>
                                    <li><Link to="/speech-therapy">Speech Therapy</Link></li>
                                    <li><Link to="/occupational-therapy">Occupational Therapy</Link></li>
                                    <li><Link to="/physical-therapy">Physical Therapy</Link></li>
                                    <li><Link to="/feeding">Feeding Therapy</Link></li>
                                    <li><Link to="/acute-nursing">Acute Nursing</Link></li>
                                    <li><Link to="/nicu">NICU Transition Program</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li><NavLink to="/request-a-service" activeClassName="selected">Request a Service</NavLink></li>
                        <li><NavLink to="/join-our-team" activeClassName="selected">Join Our Team</NavLink></li>
                        <li><NavLink to="/resources" activeClassName="selected">Resources</NavLink></li>
                        {
                            auth.user ?
                                <>
                                <li className="login"><NavLink to="/patient-portal" activeClassName="selected">Patient Portal</NavLink></li>
                                </>   
                            :
                                <>
                                <li className="login"><NavLink to="/patient-login" activeClassName="selected">Patient Login</NavLink></li>
                                </>
                        }
                        {
                            auth.user ?
                            (
                                auth.user.email !== 'patient@sparkhomehealth.com' ?
                                <>
                                <li className="login"><NavLink to="/employee-portal" activeClassName="selected">Employee Portal</NavLink></li>
                                </>   
                            :
                                <>
                                <li className="login"><NavLink to="/employee-login" activeClassName="selected">Employee Login</NavLink></li>
                                </>
                            )
                            :
                            (
                                <>
                                <li className="login"><NavLink to="/employee-login" activeClassName="selected">Employee Login</NavLink></li>
                                </>
                            )
                        }
                    </ul>
                </div>
                <div onClick={handleBurger} className={`hamburger ${burgerClicked ? 'clicked' : ''}`}>
                    <div className="hamburger__bar" />
                    <div className="hamburger__bar" />
                    <div className="hamburger__bar" />
                </div>
                <div className={`overlay ${burgerClicked ? 'show' : ''}`}>
                    <div className="overlay__list">
                        <ul>
                            <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/about-us">About Us</Link></li>
                            <li className="overlay-link" onClick={() => setShowDropdown(!showDropdown)}>About Our Services
                                <div className={`overlay-link__dropdown ${showDropdown ? "show" : ""}`}>
                                    <ul>
                                        <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/about-our-services">All Services</Link></li>
                                        <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/speech-therapy">Speech Therapy</Link></li>
                                        <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/occupational-therapy">Occupational Therapy</Link></li>
                                        <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/physical-therapy">Physical Therapy</Link></li>
                                        <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/feeding">Feeding Therapy</Link></li>
                                        <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/acute-nursing">Acute Nursing</Link></li>
                                        <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/nicu">NICU Transition Program</Link></li>
                                    </ul>
                                </div>
                            </li>
                            <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/request-a-service">Request a Service</Link></li>
                            <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/join-our-team">Join Our Team</Link></li>
                            <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/resources">Resources</Link></li>
                            {
                            auth.user ?
                                <>
                                <li onClick={() => setBurgerClicked(!burgerClicked)} className="login"><NavLink to="/patient-portal" activeClassName="selected">Patient Portal</NavLink></li>
                                </>   
                            :
                                <>
                                <li onClick={() => setBurgerClicked(!burgerClicked)} className="login"><NavLink to="/patient-login" activeClassName="selected">Patient Login</NavLink></li>
                                </>
                            }
                            {
                                auth.user ?
                                (
                                    auth.user.email !== 'patient@sparkhomehealth.com' ?
                                    <>
                                    <li onClick={() => setBurgerClicked(!burgerClicked)} className="login"><NavLink to="/employee-portal" activeClassName="selected">Employee Portal</NavLink></li>
                                    </>   
                                :
                                    <>
                                    <li onClick={() => setBurgerClicked(!burgerClicked)} className="login"><NavLink to="/employee-login" activeClassName="selected">Employee Login</NavLink></li>
                                    </>
                                )
                                :
                                (
                                    <>
                                    <li onClick={() => setBurgerClicked(!burgerClicked)} className="login"><NavLink to="/employee-login" activeClassName="selected">Employee Login</NavLink></li>
                                    </>
                                )
                            }
                            {
                                auth.user ?
                                (
                                    auth.user.email === 'admin@sparkhomehealth.com' ?
                                    <>
                                    <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/admin-portal">Admin Portal</Link></li>
                                    </>   
                                :
                                    <>
                                    <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/contact-us">Contact Us</Link></li>
                                    </>
                                )
                                :
                                (
                                    <>
                                    <li onClick={() => setBurgerClicked(!burgerClicked)}><Link to="/contact-us">Contact Us</Link></li>
                                    </>
                                )
                            }
                        </ul>
                    </div>
                </div>
                {
                    auth.user ?
                    (
                        auth.user.email === 'admin@sparkhomehealth.com' ?
                        <div className="admin-cta">
                            <button><Link to="/admin-portal">Admin</Link></button>
                        </div>   
                    :
                        <div className="contact-cta">
                            <button><Link to="/contact-us">Contact Us</Link></button>
                        </div>
                    )
                    :
                    (
                        <div className="contact-cta">
                            <button><Link to="/contact-us">Contact Us</Link></button>
                        </div>
                    )
                }
                {/* <div className="covid-cta">
                    <button><Link to="/covid-response">Our Covid-19 Policies</Link></button>
                </div> */}
            </div>
        </div>
    )
}

export default Navigation