import { resources } from '../../const/resources'
import { useState, useEffect } from 'react'
import ResourceMobileRender from './ResourceMobileRender'

interface mobileProps {
    isMobile: boolean,
}

const ResourcesMobile: React.FC<mobileProps> = (props) => {
    const [ currentPages, setCurrentPages ] = useState<any>(null)
    const [ isScrolled, setIsScrolled ] = useState<boolean>(false)

    const handleClick = (e) => {
        window.scrollTo(0, 0);
        setCurrentPages(resources.filter(page => page.title === e.target.innerHTML))
    }

    const handleGoBack = () => {
        setCurrentPages(null)
    }

    const handleToTop = () => {
        window.scrollTo(0,0)
    }

    const scrollToggle = () => {
        window.scrollY > 100 ? setIsScrolled(true) : setIsScrolled(false)
    }

    window.addEventListener('scroll', scrollToggle)

    return (
        <div className={`resources-mobile ${props.isMobile ? "mobile" : ""}`}>
            <h1>Resources</h1>
            {
                currentPages ? 
                <div className="pages">
                    <button onClick={handleGoBack}>Go Back</button>
                    <ResourceMobileRender currentPages={currentPages} />
                </div>
                :
                <div>
                    {
                        resources.map((resource, idx) => 
                            resource.TOC ?
                            <div key={idx} className="list">
                                <p onClick={handleClick}>{resource.title}</p>
                            </div>
                            :
                            null
                        )
                    }
                </div>
            }
            <div className={`${isScrolled ? "back-to-top-btn" : ""}`} onClick={handleToTop} />
        </div>
    )
}

export default ResourcesMobile