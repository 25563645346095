const CovidResponseBubbles: React.FC = () => {
    return (
        <div>
            <div className="covid-response-bubble1" />
            <div className="covid-response-bubble2" />
            <div className="covid-response-bubble3" />
            <div className="covid-response-bubble4" />
            <div className="covid-response-bubble5" />
            <div className="covid-response-bubble6" />
            <div className="covid-response-bubble7" />
            <div className="covid-response-bubble8" />
            <div className="covid-response-bubble9" />
            <div className="covid-response-bubble10" />
            <div className="covid-response-bubble11" />
            <div className="covid-response-bubble12" />
            <div className="covid-response-bubble13" />
            <div className="covid-response-bubble14" />
            <div className="covid-response-bubble15" />
            <div className="covid-response-bubble16" />
        </div>
    )
}

export default CovidResponseBubbles