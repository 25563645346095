const ContactUsBubbles: React.FC = () => {
    return (
        <div>
            <div className="contact-us-bubble1" />
            <div className="contact-us-bubble2" />
            <div className="contact-us-bubble3" />
            <div className="contact-us-bubble4" />
            <div className="contact-us-bubble5" />
            <div className="contact-us-bubble6" />
            <div className="contact-us-bubble7" />
            <div className="contact-us-bubble8" />
            <div className="contact-us-bubble9" />
            <div className="contact-us-bubble10" />
            <div className="contact-us-bubble11" />
            <div className="contact-us-bubble12" />
            <div className="contact-us-bubble13" />
            <div className="contact-us-bubble14" />
            <div className="contact-us-bubble15" />
            <div className="contact-us-bubble16" />
        </div>
    )
}

export default ContactUsBubbles